import React from 'react'
import {faCircleExclamation} from '@fortawesome/free-solid-svg-icons/faCircleExclamation'
import {FontAwesomeIcon, FontAwesomeIconProps} from '@fortawesome/react-fontawesome'

type MemberCircleExclamationIconProps = Pick<FontAwesomeIconProps, 'color' | 'size'> & {
  mr?: string;
}
export const MemberCircleExclamationIcon: React.VFC<MemberCircleExclamationIconProps> = (
  {color, size, mr},
) => {
  return <FontAwesomeIcon
    icon={faCircleExclamation}
    color={color}
    size={size}
    style={{marginRight: mr}}
  />
}
