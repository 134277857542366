import {createTheme} from '@mui/material/styles'
import {Color} from './Views/Styles'
import {DefaultFontFamily} from './AdminGlobalStyles'

export const theme = createTheme({
  membucket: {
    size: {
      formWidth: 768,
      largeFormWidth: 1176,
      largeTableWidth: 1176,
    },
  },
  typography: {
    fontFamily: `${DefaultFontFamily}`,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:focus': {
            border: `1px solid ${Color.Focus}`,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: `${Color.White}`,
          fontSize: '14px',
          color: `${Color.NewGrey900}`,
        },
        input: {
          height: 'auto',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${Color.Primary400}`,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: `${Color.Primary400}`,
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            backgroundColor: `${Color.NewGrey300}`,
          },
          '&:disabled .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
        multiline: {
          padding: 0,
        },
        input: {
          padding: '0 8px',
        },
        notchedOutline: {
          borderColor: `${Color.NewGrey200}`,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: `${Color.NewGrey900}`,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: `${Color.Primary400}`,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: `${Color.NewGrey800}`,
          },
          '&.Mui-disabled': {
            opacity: 1,
            cursor: 'default',
          },
          '&.Mui-selected': {backgroundColor: `${Color.NewGrey100}`},
          '&.MuiListItem-hover:hover': {backgroundColor: `${Color.NewGrey800}`},
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          background: `${Color.White}`,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: `${Color.NewGrey900}`,
          borderBottom: `1px solid ${Color.NewGrey400}`,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '&.MuiDialogActions-spacing': {
            '.MuiButton-root': {
              '&:not(:first-of-type)': {
                marginLeft: '24px',
              },
            },
          },
        },
      },
    },
  },
  palette: {
    primary: {
      // light: '#757ce8',
      main: `${Color.Primary500}`,
      // main: '#0C606A',
      // dark: '#15A6B7',
      // contrastText: '#fff',
    },
    secondary: {
      // light: '#ff7961',
      main: `${Color.Accent600}`,
      // dark: '#ba000d',
      // contrastText: '#000',
    },
    warning: {
      main: Color.Accent600,
    },
    action: {
      disabledBackground: '#808080',
      disabled: '#fff',
    },
  },
})
