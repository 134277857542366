import styled from '@emotion/styled'
import {DEFAULT_SPACING} from '../Styles'

export type FlexContainerProps = {
  alignItems?: 'normal' | 'center' | 'baseline' | 'flex-start'
  verticalAlign?: 'baseline' | 'middle'
  justifyContent?: 'flex-start' | 'center' | 'flex-end'
  textAlign?: 'start' | 'center' | 'end'
  gap?: string | number
}

const getGapStyle = ({gap}: FlexContainerProps): string => {
  if (!gap) {
    return 'normal'
  }
  if (typeof gap === 'string') {
    return gap
  }
  return `${DEFAULT_SPACING * gap}px`
}

export const FlexContainer = styled.div`
  display: flex;
  align-items: ${({alignItems}: FlexContainerProps): string => alignItems ?? 'normal'};
  vertical-align: ${({verticalAlign}: FlexContainerProps): string => verticalAlign ?? 'baseline'};
  justify-content: ${({justifyContent}: FlexContainerProps): string => justifyContent ?? 'flex-start'};
  text-align: ${({textAlign}: FlexContainerProps): string => textAlign ?? 'start'};
  gap: ${getGapStyle};
`

export const ColumnFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({alignItems}: FlexContainerProps): string => alignItems ?? 'normal'};
  vertical-align: ${({verticalAlign}: FlexContainerProps): string => verticalAlign ?? 'baseline'};
  justify-content: ${({justifyContent}: FlexContainerProps): string => justifyContent ?? 'flex-start'};
  text-align: ${({textAlign}: FlexContainerProps): string => textAlign ?? 'start'};
  gap: ${getGapStyle};
`
