import React from 'react'
import styled from '@emotion/styled'
import {Color} from '../../../Styles'
import {Typography} from '../../../Atoms/Typographies'
import {FlexContainer} from '../../../Atoms/Containers'
import {Spacer} from '../../../../../Common/Components/Spacer'
import {MemberErrorExclamationIcon} from '../../../Atoms/Icons/MemberErrorExclamationIcon'

export type ErrorSummaryProps = {
  display: boolean
  headline: string
  detail?: string
  testId?: string
}

export const ErrorSummary: React.VFC<ErrorSummaryProps> = (props) => {
  return (
    <>
      {props.display && (
        <ErrorSummaryCard data-testid={props.testId ?? 'div-error-alert'}>
          <ErrorSummaryHeadline headline={props.headline}/>
          {props.detail && (
            <Spacer mt={'10px'}>
              <FlexContainer textAlign={'center'} justifyContent={'center'}>
                <ErrorSummaryDetailMessage>{props.detail}</ErrorSummaryDetailMessage>
              </FlexContainer>
            </Spacer>
          )}
        </ErrorSummaryCard>
      )}
    </>
  )
}

export const ErrorSummaryCard = styled.div`
  margin: 30px auto 20px;
  padding: 15px 16px 12px 16px;
  border: 2px solid ${Color.Red500};
  border-radius: 5px;
  background: ${Color.White};
  max-width: 600px;
`

const HeadlineArea = styled.div`
  text-align: center;
`

type ErrorSummaryHeadlineMessageProps = {
  children: React.ReactNode
}
const ErrorSummaryHeadlineMessage: React.VFC<ErrorSummaryHeadlineMessageProps> = (props) => {
  return (
    <Typography
      display={'inline'}
      verticalAlign={'middle'}
      fontRemSize={1.6}
      bold
      color={Color.Red500}
    >
      {props.children}
    </Typography>
  )
}

type ErrorSummaryHeadlineProps = {
  headline: string
}
export const ErrorSummaryHeadline: React.VFC<ErrorSummaryHeadlineProps> = (
  {headline},
) => {
  return (
    <HeadlineArea>
      <MemberErrorExclamationIcon/>
      <ErrorSummaryHeadlineMessage>{headline}</ErrorSummaryHeadlineMessage>
    </HeadlineArea>
  )
}

type ErrorSummaryDetailMessageProps = {
  children: React.ReactNode
}
const ErrorSummaryDetailMessage: React.VFC<ErrorSummaryDetailMessageProps> = (props) => {
  return (
    <Typography fontRemSize={1.3} bold color={Color.Red500}>
      {props.children}
    </Typography>
  )
}
