import React, {useCallback} from 'react'
import styled from '@emotion/styled'
import {Color} from '../../../Styles'
import {Spacer} from '../../../../../Common/Components/Spacer'
import {PIMStack} from '../../Layouts/Bases/PIMStack'
import {MemberCircleChevronRightIcon} from '../../Icons/MemberCircleChevronRightIcon'

export type PIMSubmitButtonProps = {
  children: React.ReactNode
  onClick: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  testId?: string
}

const getBackgroundStyle = ({disabled}: PIMSubmitButtonProps): string => (
  disabled ? `${Color.PrimaryButtonDisabledColor}` : `${Color.PrimaryButtonColor}`
)
const getBorderStyle = ({disabled}: PIMSubmitButtonProps): string => (
  disabled ? `solid 1px ${Color.PrimaryButtonDisabledColor}` : `solid 1px ${Color.PrimaryButtonColor}`
)
const getColorStyle = ({disabled}: PIMSubmitButtonProps): string => (
  disabled ? `rgba(255, 255, 255, 0.4)` : `${Color.White}`
)
const getOpacityStyle = ({disabled}: PIMSubmitButtonProps): number => disabled ? 0.4 : 1
const getPointerEventsStyle = ({disabled}: PIMSubmitButtonProps): string => (
  disabled ? 'none' : 'auto'
)

const ICON_SIZE = 18
const StyledButton = styled.button`
  width: 100%;
  max-width: 305px;
  height: 47px;
  background: ${getBackgroundStyle};
  border: ${getBorderStyle};
  border-radius: 6px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .05);
  padding: 0 16px 0 ${ICON_SIZE + 16}px;
  font-size: 1.6rem;
  font-weight: bold;
  color: ${getColorStyle};
  opacity: ${getOpacityStyle};
  pointer-events: ${getPointerEventsStyle};
  @media (min-width: 769px) {
    &:focus {
      opacity: 0.6;
    }

    &:hover {
      background: ${Color.PrimaryButtonHoverColor};
      border-color: ${Color.PrimaryButtonHoverColor};
    }
  }
`

export const PIMSubmitButton: React.VFC<PIMSubmitButtonProps> = (props) => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const eventTarget = e.target as HTMLElement
      eventTarget.blur()
      props.onClick(e)
    },
    [props.onClick],
  )

  return (
    <StyledButton
      data-testid={props.testId}
      onClick={handleClick}
      disabled={props.disabled}
    >
      <PIMStack direction={'row'} alignItems={'center'}>
        <Spacer m={'auto'}>
          {props.children}
        </Spacer>
        <MemberCircleChevronRightIcon/>
      </PIMStack>
    </StyledButton>
  )
}
