import React from 'react'
import {BucketInfo} from '../../../DTO/BucketInfoDTO'
import {MBLogoImg} from '../../Atoms/MBLogos'
import {Typography} from '../../Atoms/Typographies'
import styled from '@emotion/styled'
import {Color} from '../../Styles'
import {SecondaryFontFamily} from '../../../MemberGlobalStyles'
import {Signboard} from '../../../DTO/SignboardDTO'
import {useTranslation} from 'react-i18next'

const MBHeaderContainer = styled.div`
  display: flex;
  background: ${Color.White};
  border-bottom: 1px solid ${Color.Grey100};
  padding: 10px 20px 9px;
  height: 48px;
`
const MBHeaderCenterArea = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

export type MembersHeaderProps = {
  bucketInfo?: Omit<BucketInfo, 'mailMagazineList'>
  signboard?: Signboard
}
export const MembersHeader: React.VFC<MembersHeaderProps> = (props) => {
  const {t} = useTranslation('member')
  const logoImageInfo = getLogoImageInfo(props)

  return <MBHeaderContainer data-testid="block-member-header">
    <MBHeaderCenterArea>
      <MBLogoImg
        logoImgMaxHeight={'32px'}
        src={logoImageInfo.src}
        alt={logoImageInfo.alt}
      />
      {
        logoImageInfo.displayHeaderTitle &&
          <MBHeaderTitleTypography fontRemSize={1.6}>
            {t('ヘッダー.見出し')}
          </MBHeaderTitleTypography>
      }
    </MBHeaderCenterArea>
  </MBHeaderContainer>
}

const MBHeaderTitleTypography = styled(Typography)`
  font-family: ${SecondaryFontFamily};
`

type LogoImageInfo = {
  src: string
  alt: string
  displayHeaderTitle: boolean
}

const getLogoImageInfo = ({bucketInfo, signboard}: MembersHeaderProps): LogoImageInfo => {
  const {t} = useTranslation('member')

  if (bucketInfo?.logoImg) {
    return {src: bucketInfo.logoImg, alt: bucketInfo.name, displayHeaderTitle: false}
  } else if (signboard) {
    return {src: signboard.logoImgUrl, alt: signboard.signupName, displayHeaderTitle: false}
  }
  return {src: t('ヘッダー.ロゴ画像のURL'), alt: t('ヘッダー.ロゴ画像の代替テキスト'), displayHeaderTitle: true}
}
