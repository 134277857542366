import React, {useContext} from 'react'
import {PIMPCLinkList, PIMPCLinkListProps} from '../Atoms/Lists/PIMPCLinkList'
import {useMedia} from 'react-use'
import {PIMSPLinkList} from '../Atoms/Lists/PIMSPLinkList'
import {Spacer} from '../../../Common/Components/Spacer'
import {useTranslation} from 'react-i18next'
import {MembersAuthContext} from '../../Auth/MembersAuthContext'

export const PIMFooterNavi: React.VFC = () => {
  const isPC = useMedia('(min-width: 769px)')
  const {t} = useTranslation('member')
  const {isMembersAuthPage} = useContext(MembersAuthContext)

  const cmsDomain = process.env.REACT_APP_CMS_DOMAIN ?? ''
  const links: PIMPCLinkListProps['links'] = [
    {id: 0, label: '利用規約', href: `${t('フッター.URL 利用規約', {cmsDomain})}`},
    {id: 1, label: '個人情報の取り扱い', href: t('フッター.URL 個人情報の取り扱い', {cmsDomain})},
  ]

  const label = 'フッター.ログアウト'
  if (isMembersAuthPage && !['', label].includes(t(label).trim())) links.push({
    id: 2, label: t(label), href: '/logout', target: '_self',
  })

  return (
    <div data-testid={'members-footer-navi'}>
      {isPC ? (
        <Spacer my={'10px'}>
          <PIMPCLinkList links={links}/>
        </Spacer>
      ) : (
        <PIMSPLinkList links={links}/>
      )}
    </div>
  )
}
