export const DEFAULT_SPACING = 8

export namespace ZIndex {
  export const LoadingOverlay = 1500
  export const Dialog = 1000
  export const DialogBack = 500
}

export namespace Color {
  export const Black = '#000000'
  export const Grey40 = '#fafafa'
  export const Grey50 = '#F0F0F0'
  export const Grey100 = '#D9D9D9'
  export const Grey200 = '#CCCCCC'
  export const Grey500 = '#ADADAD'
  export const Grey600 = '#999999'
  export const Grey700 = '#808080'
  export const Grey750 = '#666666'
  export const Grey800 = '#333333'
  export const Red500 = '#E50028'
  export const Red600 = '#E6001D'
  export const Green400 = '#0CA789'
  export const Green500 = '#008069'
  export const Blue200 = '#1976d2'
  export const White = '#FFFFFF'

  export const PrimaryColor = '#8f8f8f'
  export const PrimarySubColor = '#b7b7b7'
  export const PrimaryAccentColor = '#ed6d1a'

  export const PrimaryButtonColor = '#ed6d1a'
  export const PrimaryButtonHoverColor = '#f4a776'
  export const PrimaryButtonDisabledColor = '#b7b7b7'

  export const ErrorInputBorderColor = '#f5a4b1'
  export const ErrorInputBackgroundColor = '#fcf2f4'

  export const AttentionBorderColor = '#ffe14c'
  export const AttentionBackgroundColor = '#fffde5'

  export const LinkButton = '#0000EE'

  export const BlackOpacity = 'rgba(0, 0, 0, .7)'
  export const BlueOpacity = 'rgba(144, 202, 249, 0.08)'
}
