import React from 'react'
import styled from '@emotion/styled'
import {Color} from '../Styles'
import {FlexContainer} from './Containers'
import {Spacer} from '../../../Common/Components/Spacer'

const DefaultTypographyColor = Color.Grey800

type TypographyProps = {
  fontRemSize: number
  bold?: boolean
  color?: string
  display?: string
  verticalAlign?: string
}

export const Typography = styled.p`
  font-size: ${({fontRemSize}: TypographyProps): number => fontRemSize}rem;
  display: ${({display}: TypographyProps): string => display ?? 'block'};
  vertical-align: ${({verticalAlign}: TypographyProps): string => verticalAlign ? verticalAlign : 'baseline'};
  font-weight: ${({bold}: TypographyProps): string => bold ? 'bold' : 'normal'};
  white-space: pre-wrap;
  color: ${({color}: TypographyProps): string => color ?? DefaultTypographyColor};
  word-break: break-all;
`

type MBContentTitleTypographyProps = {
  children: React.ReactNode
}
export const MBContentTitleTypography: React.VFC<MBContentTitleTypographyProps> = (props) => {
  return (
    <Typography
      fontRemSize={1.6}
      bold={true}
    >
      {props.children}
    </Typography>
  )
}

type MBContentSubTitleTypographyProps = {
  whiteSpace?: string
  children: React.ReactNode
}
export const MBContentSubTitleTypography: React.VFC<MBContentSubTitleTypographyProps> = (props) => {
  return (
    <Typography
      fontRemSize={1.3}
      bold={true}
    >
      {props.children}
    </Typography>
  )
}

type MBContentBodyTypographyProps = {
  children: React.ReactNode
}
export const MBContentBodyTypography: React.VFC<MBContentBodyTypographyProps> = (props) => {
  return (
    <Typography
      fontRemSize={1.5}
    >
      {props.children}
    </Typography>
  )
}

type MBContentBodyItemTypographyProps = Pick<TypographyProps, 'color'> & {
  children: React.ReactNode
}
export const MBContentBodyItemTypography: React.VFC<MBContentBodyItemTypographyProps> = (props) => {
  return (
    <Typography
      fontRemSize={1.3}
      color={props.color}
    >
      {props.children}
    </Typography>
  )
}

export const MBContentBodyItemWarningMessageTypography: React.VFC<MBContentBodyItemTypographyProps>
  = (props) => {
  return (
    <MBContentBodyItemTypography
      color={Color.Red500}
    >
      {props.children}
    </MBContentBodyItemTypography>
  )
}

export const MBContentBodyItemDisabledTypography: React.VFC<MBContentBodyItemTypographyProps>
  = (props) => {
  return (
    <MBContentBodyItemTypography
      color={Color.Grey600}
    >
      {props.children}
    </MBContentBodyItemTypography>
  )
}

export type MBContentNoteTypographyProps = Pick<TypographyProps, 'color'> & {
  children: React.ReactNode
}
export const MBContentNoteTypography: React.VFC<MBContentNoteTypographyProps> = (props) => {
  return (
    <Typography
      fontRemSize={1}
      color={props.color}
    >
      {props.children}
    </Typography>
  )
}

const MBContentNoteMarkTypography: React.VFC = () => {
  return (
    <Typography
      fontRemSize={1}
      color={Color.Red500}
    >
      ※
    </Typography>
  )
}

export type MBContentNoteWithMarkTypographyProps = {
  note: string | JSX.Element
  subNotes?: string[]
}
export const MBContentNoteWithMarkTypography: React.VFC<MBContentNoteWithMarkTypographyProps> = (
  props,
) => {
  return (
    <>
      <FlexContainer>
        <MBContentNoteMarkTypography/>
        <MBContentNoteTypography>{props.note}</MBContentNoteTypography>
      </FlexContainer>
      {
        props.subNotes && props.subNotes.map((item: string, index: number) =>
          <Spacer ml={'20px'} key={index}>
            <MBContentNoteTypography>{item}</MBContentNoteTypography>
          </Spacer>,
        )
      }
    </>
  )
}

export const MBDialogContentTypography: React.VFC<{ children: React.ReactNode }> = (props) => {
  return (
    <Typography
      fontRemSize={1.5}
      color={Color.Grey600}
    >
      {props.children}
    </Typography>
  )
}

type MBContentFlexWrapTypographyProps = {
  children: React.ReactNode
}
export const MBContentFlexWrapTypography: React.VFC<MBContentFlexWrapTypographyProps> = (props) => {
  return (
    <Typography
      fontRemSize={1.3}
      bold={true}
      display={'flex'}
      style={{flexWrap: 'wrap'}}
    >
      {props.children}
    </Typography>
  )
}
