import {Signboard, SignboardDTO, SignboardJSON} from './SignboardDTO'
import {PaymentMethod, PaymentMethodDTO, PaymentMethodJSON} from './PaymentMethodDTO'
import {ExtendMetaData, ExtendMetaDataDTO} from './ExtendMetaDataDTO'

export interface InvitationInfoJSON {
  mail: string,
  signboardInfo: SignboardJSON
  paymentMethodList: PaymentMethodJSON[]
  extendMetaData: ExtendMetaData[]
}

export interface InvitationInfo {
  mail: string,
  signboard: Signboard
  paymentMethodList: PaymentMethod[]
  extendMetaData: ExtendMetaData[]
}

export class InvitationInfoDTO implements InvitationInfo {
  mail: string
  signboard: Signboard
  paymentMethodList: PaymentMethod[]
  extendMetaData: ExtendMetaData[]

  constructor(
    mail: string,
    signboard: Signboard,
    paymentMethodList: PaymentMethod[],
    extendMetaData: ExtendMetaData[],
  ) {
    this.mail = mail
    this.signboard = signboard
    this.paymentMethodList = paymentMethodList
    this.extendMetaData = extendMetaData
  }

  static fromJSON(jsonObject: InvitationInfoJSON): InvitationInfoDTO {
    return new InvitationInfoDTO(
      jsonObject.mail,
      {...SignboardDTO.fromJSON(jsonObject.signboardInfo)},
      jsonObject.paymentMethodList.map(item => ({...PaymentMethodDTO.fromJSON(item)})),
      jsonObject.extendMetaData.map(item => ({...ExtendMetaDataDTO.fromJSON(item)})),
    )
  }
}
