import React from 'react'
import styled from '@emotion/styled'
import {PIMStack} from '../Layouts/Bases/PIMStack'
import {MBContentTitleDivider} from '../Dividers'
import {MBLogoImg} from '../MBLogos'
import {MBContentTitleTypography} from '../Typographies'
import {MBRequiredBadge} from '../Badges'
import {Color} from '../../Styles'

export type PIMCardProps = {
  children: React.ReactNode
  headerProps?: PIMCardHeaderProps
  testId?: string
} & StyledDivProps

type StyledDivProps = {
  pt?: string
  pb?: string
}
const StyledDiv = styled.div<StyledDivProps>`
  display: inline-block;
  padding-top: ${({pt}): string => pt ?? '16px'};
  padding-bottom: ${({pb}): string => pb ?? '16px'};
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 6px;
  background: ${Color.White};
  width: 100%;
  max-width: 600px;
`

export const PIMCard: React.VFC<PIMCardProps> = (props) => {
  const {children, headerProps, testId, ...styledDivProps} = props

  return (
    <StyledDiv data-testid={testId} {...styledDivProps}>
      <PIMStack spacing={3}>
        {headerProps && (
          <PIMCardHeader {...headerProps}/>
        )}
        <div>{children}</div>
      </PIMStack>
    </StyledDiv>
  )
}

export const PIMCardNoTopMessage: React.VFC<PIMCardProps> = (props) => {
  return (
    <StyledDiv data-testid={props.testId}>
      <PIMStack spacing={'18px'}>
        {props.headerProps && (
          <PIMCardHeader {...props.headerProps}/>
        )}
        <div>{props.children}</div>
      </PIMStack>
    </StyledDiv>
  )
}

type PIMCardHeaderProps = {
  title: string
  require?: boolean
  logoUrl?: string
}
const PIMCardHeader: React.VFC<PIMCardHeaderProps> = (props) => {
  return (
    <PIMStack spacing={'7px'}>
      <PIMStack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        spacing={1}
      >
        <PIMStack direction={'row'} alignItems={'center'} spacing={2}>
          <MBContentTitleTypography>{props.title}</MBContentTitleTypography>
          {props.require && <MBRequiredBadge/>}
        </PIMStack>
        {
          props.logoUrl && (
            <MBLogoImg
              data-testid={'content-block-logo'}
              logoImgMaxHeight={'30px'}
              logoImgMaxWidth={'58px'}
              src={props.logoUrl}
              alt={'content-header-log'}
            />
          )
        }
      </PIMStack>
      <MBContentTitleDivider/>
    </PIMStack>
  )
}
