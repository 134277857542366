import React from 'react'
import {PIMStack, PIMStackProps} from './Bases/PIMStack'

export type PIMPageActionsProps = Pick<PIMStackProps, 'children' | 'spacing'>

export const PIMPageActions: React.VFC<PIMPageActionsProps> = (props) => {
  return (
    <PIMStack alignItems={'center'} spacing={props.spacing ?? 2.5}>
      {props.children}
    </PIMStack>
  )
}
