import FetchWrapper, {Body} from './FetchWrapper'
import {HttpError} from './HttpError'
import {Nullable, Optional} from '../Common/TypeHelper'
import {isAuthEnabled} from '../Common/common-msa'
import {acquireMembersAccessToken} from '../Members/Auth/members-msa'
import {acquireAdminsAccessToken} from '../Admins/Auth/admins-msa'
import {CommonResponseJSON, CommonResponseStatus, CommonResponseStatusType} from './CommonResponse'
import {MBMemberError} from '../Members/Common/MBMemberError'

class BrowserFetchWrapper implements FetchWrapper {
  private readonly headers: HeadersInit | undefined

  constructor(headers?: HeadersInit) {
    this.headers = headers
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async fetchWithDefaultHeader(url: RequestInfo, options: RequestInit): Promise<any> {
    const {headers, ...rest} = {...options}

    const mergedOptions = {
      ...rest,
      headers: {
        ...this.headers,
        ...headers,
      },
    }

    const functionAcquireToken = getFunctionAcquireToken(mergedOptions.headers)
    if (functionAcquireToken) {
      const token = await functionAcquireToken()
      mergedOptions.headers = {...mergedOptions.headers, Authorization: `Bearer ${token}`}
    }
    return getResponseJson(fetchWrapper(url, mergedOptions))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getByJson(url: string, options?: RequestInit): Promise<any> {
    const getOptions: RequestInit = {
      ...options,
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
    }
    return this.fetchWithDefaultHeader(url, getOptions)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  postJson(url: string, body: Body, option?: RequestInit): Promise<any> {
    const headers = option?.headers
    const postOptions: RequestInit = {
      ...option,
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }

    return this.fetchWithDefaultHeader(url, postOptions)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  putJson(url: string, body: Body, option?: RequestInit): Promise<any> {
    const headers = option?.headers
    const postOptions: RequestInit = {
      ...option,
      method: 'PUT',
      mode: 'cors',
      credentials: 'include',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }

    return this.fetchWithDefaultHeader(url, postOptions)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteJson(url: string, option?: RequestInit): Promise<any> {
    const headers = option?.headers
    const postOptions: RequestInit = {
      ...option,
      method: 'DELETE',
      mode: 'cors',
      credentials: 'include',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
    }

    return this.fetchWithDefaultHeader(url, postOptions)
  }
}

const fetchWrapper = (url: RequestInfo, options: RequestInit): Promise<Response> => {
  return fetch(url, options)
    .then(response => {
      // Bodyがあれば詳細情報を含むCommonResponseJSONと考えられるためここではエラーにしない
      if (response.headers.get('content-length') === '0') {
        switch (response.status) {
          case 401:
            throw new HttpError('Unauthorized')
          case 403:
            throw new HttpError('Forbidden')
        }
      }
      return response
    })
}

const getResponseJson = (
  response: Promise<Response>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<CommonResponseJSON<any>> => {
  return response.then(async (response) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const responseJson: CommonResponseJSON<any> = await response.json()
    checkDisagreementError(responseJson.status)
    return responseJson
  }).catch((e: Error) => {
    throw new MBMemberError(e.message)
  })
}

const checkDisagreementError = (responseJsonStatus: CommonResponseStatusType): void => {
  if (responseJsonStatus === CommonResponseStatus.DisagreementError) {
    throw new MBMemberError('DisagreementError')
  }
}

export const emptyRequestInit: RequestInit = {}

const getFunctionAcquireToken = (headers: HeadersInit): Nullable<() => Promise<string>> =>
  isAuthTypeMembers(headers) ? acquireMembersAccessToken
    : isAuthTypeAdmins(headers) ? acquireAdminsAccessToken : null

export const Authorization = {
  Members: 'REQUIRE_MEMBERS_BEARER_TOKEN',
  Admins: 'REQUIRE_ADMINS_BEARER_TOKEN',
}
type AuthorizationType = typeof Authorization[keyof typeof Authorization]

const isAuthTypeMembers = (headers: HeadersInit): boolean =>
  isAuthorizationType(Authorization.Members, headers)

const isAuthTypeAdmins = (headers: HeadersInit): boolean =>
  isAuthorizationType(Authorization.Admins, headers)

const isAuthorizationType = (
  authorizationType: AuthorizationType,
  headers: Optional<HeadersInit>,
): boolean =>
  !!(headers && 'Authorization' in headers && headers.Authorization === authorizationType)

export const withMembersAuth = (options: RequestInit = {}): RequestInit =>
  withAuth(options, Authorization.Members)

export const withAdminsAuth = (options: RequestInit = {}): RequestInit =>
  withAuth(options, Authorization.Admins)

const withAuth = (options: RequestInit = {}, authorizationType: AuthorizationType): RequestInit => {
  if (!isAuthEnabled()) {
    return options
  }

  options.headers = {...options.headers, Authorization: authorizationType}
  return options
}

export default BrowserFetchWrapper
