import React from 'react'
import {useTranslation} from 'react-i18next'
import {Color} from '../../Styles'
import styled from '@emotion/styled'

const StyledFooter = styled.footer`
  width: 100%;
  background: ${Color.PrimaryColor};
  padding: 20px 0;
`
const StyledCopyRight = styled.div`
  font-size: 9px;
  text-align: center;
  color: ${Color.White};
`

export const MembersFooter: React.FC = () => {
  const {t} = useTranslation('member')
  return (
    <StyledFooter>
      <StyledCopyRight>&copy;{t('フッター.コピーライト')}</StyledCopyRight>
    </StyledFooter>
  )
}

