import React from 'react'
import styled from '@emotion/styled'
import {Color} from '../Styles'

type MBBaseDividerProps = {
  borderColor: string
  dividerWidth?: number
  width?: string
}
export const MBBaseDivider = styled.hr`
  height: ${({dividerWidth}: MBBaseDividerProps): number => dividerWidth ?? 1}px;
  width: ${({width}: MBBaseDividerProps): string => width ?? 'auto'};
  margin: 0;
  border: 0;
  border-top: ${({borderColor, dividerWidth}: MBBaseDividerProps): string => `${dividerWidth ?? 1}px solid ${borderColor}`};
`

export const MBContentTitleDivider: React.VFC = () => {
  return <MBBaseDivider borderColor={Color.PrimaryAccentColor} dividerWidth={2}/>
}

export const MBContentBodyDivider: React.VFC = () => {
  return <MBBaseDivider borderColor={Color.Grey100}/>
}

export const MBStepDivider: React.VFC = () => {
  return <MBBaseDivider borderColor={Color.Grey800} width={'40px'}/>
}

type MBBaseVerticalDividerProps = {
  borderColor: string
  dividerWidth?: number
  width?: string
}
export const MBBaseVerticalDivider = styled.hr`
  width: ${({dividerWidth}: MBBaseVerticalDividerProps): number => dividerWidth ?? 1}px;
  height: ${({width}: MBBaseVerticalDividerProps): string => width ?? 'auto'};
  margin: 0;
  border: 0;
  border-right: ${({borderColor, dividerWidth}: MBBaseVerticalDividerProps): string => `${dividerWidth ?? 1}px solid ${borderColor}`};
`
