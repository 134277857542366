import {MemberPreRegisterContractInfo} from '../Props/ContractInputInfo'
import {PreMemberRegisterInformation} from './PreMemberRegisterInformationDTO'
import {Optional} from '../../Common/TypeHelper'
import {EnrollmentReasonSelectedInfo} from '../View/Components/InputContentBlocks/EnrollmentReasonSelectContentBlock'
import {ExtendMetaDataInputInfo} from './ExtendMetaDataDTO'

export type SignupPreRegisterInfoJSON = SignupPreRegisterInfo

export type SignupPreRegisterInfo = Pick<PreMemberRegisterInformation,
  'invitationId' |
  'familyName' |
  'firstName' |
  'familyNameKana' |
  'firstNameKana' |
  'gender' |
  'birthday' |
  'postCode' |
  'addressPrefectureCode' |
  'addressCity' |
  'addressStreet' |
  'addressBuilding' |
  'phoneMobile' |
  'phoneTel' |
  'mailMagazineIdCheckedList' |
  'contractInfo' |
  'enrollmentReason' |
  'extendMetaData'>

export class SignupPreRegisterInfoDTO implements SignupPreRegisterInfo {
  invitationId: string
  familyName: string
  firstName: string
  familyNameKana: string
  firstNameKana: string
  gender: string
  birthday: string
  postCode: string
  addressPrefectureCode: string
  addressCity: string
  addressStreet: string
  addressBuilding: Optional<string>
  phoneMobile: Optional<string>
  phoneTel: Optional<string>
  mailMagazineIdCheckedList: number[]
  contractInfo: MemberPreRegisterContractInfo
  enrollmentReason: Optional<EnrollmentReasonSelectedInfo>
  extendMetaData?: ExtendMetaDataInputInfo

  constructor(
    invitationId: string,
    familyName: string, firstName: string,
    familyNameKana: string, firstNameKana: string,
    gender: string,
    birthday: string,
    postCode: string,
    addressPrefectureCode: string,
    addressCity: string, addressStreet: string, addressBuilding: Optional<string>,
    phoneMobile: Optional<string>, phoneTel: Optional<string>,
    mailMagazineIdCheckedList: number[],
    contractInfo: MemberPreRegisterContractInfo,
    enrollmentReason: Optional<EnrollmentReasonSelectedInfo>,
    extendMetaData?: ExtendMetaDataInputInfo,
  ) {
    this.invitationId = invitationId
    this.familyName = familyName
    this.firstName = firstName
    this.familyNameKana = familyNameKana
    this.firstNameKana = firstNameKana
    this.gender = gender
    this.birthday = birthday
    this.postCode = postCode
    this.addressPrefectureCode = addressPrefectureCode
    this.addressCity = addressCity
    this.addressStreet = addressStreet
    this.addressBuilding = addressBuilding
    this.phoneMobile = phoneMobile
    this.phoneTel = phoneTel
    this.mailMagazineIdCheckedList = mailMagazineIdCheckedList
    this.contractInfo = contractInfo
    this.enrollmentReason = enrollmentReason
    this.extendMetaData = extendMetaData
  }

  static fromJSON(jsonObject: SignupPreRegisterInfoJSON): SignupPreRegisterInfoDTO {
    return new SignupPreRegisterInfoDTO(
      jsonObject.invitationId,
      jsonObject.familyName,
      jsonObject.firstName,
      jsonObject.familyNameKana,
      jsonObject.firstNameKana,
      jsonObject.gender,
      jsonObject.birthday,
      jsonObject.postCode,
      jsonObject.addressPrefectureCode,
      jsonObject.addressCity,
      jsonObject.addressStreet,
      jsonObject.addressBuilding,
      jsonObject.phoneMobile,
      jsonObject.phoneTel,
      jsonObject.mailMagazineIdCheckedList,
      jsonObject.contractInfo,
      jsonObject.enrollmentReason,
      jsonObject.extendMetaData,
    )
  }
}
