import React from 'react'
import {PageTitle} from '../Molecules/BaseMembersPages/PageTitle'
import {Signboard} from '../../DTO/SignboardDTO'
import {useAsync} from 'react-use'
import {MBMemberError} from '../../Common/MBMemberError'
import MemberAgreementRepo from '../../Repo/MemberAgreementRepo'
import {PIMStack} from '../Atoms/Layouts/Bases/PIMStack'
import {PIMCard} from '../Atoms/Cards/PIMCard'
import {Spacer} from '../../../Common/Components/Spacer'
import {PIMPageActions} from '../Atoms/Layouts/PIMPageActions'
import {PIMSubmitButton} from '../Atoms/Buttons/Bases/PIMSubmitButton'
import {PIMSecondaryButton} from '../Atoms/Buttons/Bases/PIMSecondaryButton'
import {memberResponseStatusCheck} from '../../Utils/MemberResponseStatusCheck'
import {SignedUpSignboard} from '../../DTO/SignedUpSignboardDTO'
import {DomToReact} from '../../../Common/TypeHelper'
import AgreementDTO from '../../DTO/AgreementDTO'

export type MembersAgreementCommonProps = {
  onAgreeClick: (agreedIdList: number[]) => void
  onDisagreeClick: (agreedIdList: number[]) => void
}

export type MembersAgreementProps = MembersAgreementCommonProps & {
  memberAgreementRepo: MemberAgreementRepo
  signboard: Signboard | SignedUpSignboard
}

export const MembersAgreement: React.VFC<MembersAgreementProps> = (props) => {
  const result = useAsync(async () =>
    await props.memberAgreementRepo.get(props.signboard.signupKey))

  if (result.loading) return null
  if (result.error) throw new MBMemberError(result.error.message)
  if (!result.value) throw new MBMemberError('UnknownError')
  memberResponseStatusCheck(result.value.status)

  const agreementList = result.value.data || []

  return <MembersAgreementList
    agreementList={agreementList}
    pageTitle={'利用規約'}
    onAgreeClick={props.onAgreeClick}
    onDisagreeClick={props.onDisagreeClick}
  />
}

export type MembersAgreementListProps = MembersAgreementCommonProps & {
  agreementList: AgreementDTO[]
  pageTitle: string
}

export const MembersAgreementList: React.VFC<MembersAgreementListProps> = (
  {agreementList, pageTitle, onAgreeClick, onDisagreeClick},
) => {
  window.scrollTo(0, 0)

  return <div data-testid={'page-agreement'}>
    <PageTitle>{pageTitle}</PageTitle>

    <PIMStack alignItems={'center'} spacing={2}>
      {agreementList?.map((agreement, i) =>
        <PIMCard testId={'block-agreement-content'} key={i} pt={'16px'} pb={'16px'}>
          <AgreementContent content={agreement.contentHtml}/>
        </PIMCard>,
      )}
    </PIMStack>

    <Spacer mt={'30px'}/>

    <PIMPageActions>
      <PIMSubmitButton
        testId={'agree-button'}
        onClick={(): void => {
          onAgreeClick(agreementList.map((agreement) => agreement.id))
        }}
      >
        同意する
      </PIMSubmitButton>
      <PIMSecondaryButton
        onClick={(): void => onDisagreeClick([])}
      >
        同意しない
      </PIMSecondaryButton>
    </PIMPageActions>
  </div>
}

const AgreementContent = (props: { content: DomToReact }): JSX.Element =>
  <div>{props.content}</div>
