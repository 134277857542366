import {Nullable} from '../../Common/TypeHelper'
import {MembersBucketDTO, MembersBucketJSON} from './MembersBucketDTO'
import {ExtendMetaDataInputInfo} from './ExtendMetaDataDTO'

export interface Member {
  mail: string
  familyName: string
  firstName: string
  familyNameKana: string
  firstNameKana: string
  gender: Nullable<string>
  birthday: Nullable<string>
  postCode: string
  addressPrefectureCode: string
  addressCity: string
  addressStreet: string
  addressBuilding: string
  phoneMobile: string
  phoneTel: string
}

export interface MemberSelfJSON {
  personalInfo: Nullable<Member>
  bucketList: MembersBucketJSON[]
  extendMetaData?: ExtendMetaDataInputInfo
}

export class MemberSelfDTO {
  constructor(
    public member: Nullable<Member>,
    public bucketList: MembersBucketDTO[],
    public extendMetaData?: ExtendMetaDataInputInfo,
  ) {
  }

  static fromJSON(jsonObject: MemberSelfJSON): MemberSelfDTO {
    return new MemberSelfDTO(
      jsonObject.personalInfo,
      jsonObject.bucketList.map(json => MembersBucketDTO.fromJSON(json)),
      jsonObject.extendMetaData,
    )
  }
}
