import React from 'react'
import styled from '@emotion/styled'
import {Color} from '../../Styles'

type Props = & JSX.IntrinsicElements['h1'] & {
  children?: string
  className?: never
}

const StyledPageTitle = styled.h1`
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  color: ${Color.White};
  padding: 8px 15px;
  margin: 0 -15px 30px;
  background: ${Color.PrimaryColor};
`

export const PageTitle: React.VFC<Props> = (props) => {
  return <StyledPageTitle {...props}>{props.children}</StyledPageTitle>
}
