import React from 'react'
import {PageTitle} from '../../Molecules/BaseMembersPages/PageTitle'
import {ErrorSummary} from '../../Molecules/ErrorsAndAlert/ErrorSummary/ErrorSummary'

export function Members500Page(): JSX.Element {
  return (
    <>
      <PageTitle>エラー</PageTitle>
      <ErrorSummary
        display={true}
        headline={'問題が発生したため、\nお探しのページを表示できません。'}
        detail={'ご迷惑をおかけし申し訳ありません。'}
      />
    </>
  )
}
