import {css} from '@emotion/react'

export const DefaultFontFamily = `${[
  'ヒラギノ角ゴ ProN',
  'Hiragino Kaku Gothic ProN',
  'メイリオ',
  'Meiryo',
  '游ゴシック Medium',
  '游ゴシック体',
  'Yu Gothic Medium',
  'YuGothic',
  'sans-serif',
].join(',')}`

export const SecondaryFontFamily = `${[
  'Hiragino Kaku Gothic Pro',
  'Meiryo',
  'Noto Sans JP',
  'sans-serif',
].join(',')}`

export const MemberGlobalStyles = css`
  html, body {
    min-height: 100%;
  }

  /*
  html5doctor.com Reset Stylesheet
  v1.6.1
  Last Updated: 2010-09-17
  Author: Richard Clark - http://richclarkdesign.com
  Twitter: @rich_clark
  */

  html, body, div, span, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  abbr, address, cite, code,
  del, dfn, em, img, ins, kbd, q, samp,
  small, strong, sub, sup, var,
  b, i,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }

  body {
    line-height: 1;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section, colgroup {
    display: block;
  }

  ul,
  ol,
  li {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }

  /* change colours to suit your needs */
  ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
  }

  /* change colours to suit your needs */
  mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: bold;
  }

  del {
    text-decoration: line-through;
  }

  abbr[title], dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .noborder {
    border-collapse: unset;
  }

  input, select {
    vertical-align: middle;
  }

  url('//fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

  #root {
    min-height: 100%;
  }
  html.members-pages {
    font-size: 62.5%;
  }
  body.members-pages {
    color: #333333;
    font-family: ${DefaultFontFamily};
    font-size: 1.3em;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;

    * {
      box-sizing: border-box;
    }

    *::before,
    *::after {
      box-sizing: inherit;
    }
  }
`
