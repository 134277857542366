import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import member from './ja/member.json'
import admin from './ja/admin.json'

export const defaultNS = 'member'
export const resources = {ja: {member, admin}}

// noinspection JSIgnoredPromiseFromCall
i18n.use(initReactI18next).init({
  // https://www.i18next.com/overview/configuration-options#logging
  // debug: true,

  // https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  resources,
  lng: 'ja',
  fallbackLng: false,
  ns: ['member', 'admin'],
  defaultNS,
  fallbackNS: false,

  // https://www.i18next.com/overview/configuration-options#missing-keys
  parseMissingKeyHandler: () => '',

  // https://www.i18next.com/overview/configuration-options#translation-defaults
  returnNull: false,
  returnEmptyString: false,
  interpolation: {
    // https://www.i18next.com/translation-function/interpolation#all-interpolation-options
    escapeValue: false, // not needed for react as it escapes by default
  },

  react: {
    // https://react.i18next.com/latest/i18next-instance
    useSuspense: false, // React17利用中のため明示的にfalse指定しておく
  },
})

export default i18n
