import React from 'react'
import styled from '@emotion/styled'
import {Color} from '../Styles'

const StyledSpan = styled.span`
  display: inline-block;
  font-size: 1rem;
  height: 1.4rem;
  padding: 0 4px;
  vertical-align: middle;
  color: ${Color.White};
  border-radius: 3px;
  font-weight: normal;
  background: ${({background}: BadgeProps): string => background ?? Color.Black};
`

type BadgeProps = {
  background?: string
  children: React.ReactNode
}
export const MBBadge: React.VFC<BadgeProps> = (props) => {
  return (
    <StyledSpan background={props.background}>
      {props.children}
    </StyledSpan>
  )
}

export const MBRequiredBadge: React.VFC = () => {
  return (
    <MBBadge>必須</MBBadge>
  )
}

export const MBOkBadge: React.VFC = () => {
  return (
    <MBBadge background={Color.Green500}>OK</MBBadge>
  )
}

export const MBNgBadge: React.VFC = () => {
  return (
    <MBBadge background={Color.Red600}>NG</MBBadge>
  )
}

export const ExpiredCardBadge: React.VFC = () => {
  return (
    <MBBadge background={Color.Grey500}>有効期限切れ</MBBadge>
  )
}

export const MBInUseBadge: React.VFC = () => {
  return (
    <MBBadge>お支払いに利用中</MBBadge>
  )
}

export const MBCvsReadyBadge: React.VFC = () => {
  return (
    <MBBadge background={Color.Grey700}>コンビニ支払い待ち</MBBadge>
  )
}
