import {ConvenienceStoreKey} from '../Props/ContractInputInfo'
import {BucketRegisterResponseData, BucketRegisterResponseDataJSON, FreeBucketRegisterResponseData, FreeBucketRegisterResponseDataJSON, fromJsonBucketMemberIdList} from './BucketRegisterResponseDataDTO'
import {CommonResponseJSON} from '../../Network/CommonResponse'
import {BucketMemberId} from './BucketMemberId'

export type CvsContractDataJSON = CvsContract & {
  method: 'convenience_store'
}

export interface CvsContract {
  convenienceStoreKey: ConvenienceStoreKey,
  paymentNumber: string,
  paymentNumberLabel: string,
  confirmationNumber: string,
  confirmationNumberLabel: string,
  contractDate: string,
  contractEndDate: string,
  paymentTerm: string,
  paymentDetailUrl: string
  bucketMemberIdList: BucketMemberId[]
}

export const isCvsContractResponseJSON = (
  response: CommonResponseJSON<BucketRegisterResponseDataJSON |
    CvsContractDataJSON |
    FreeBucketRegisterResponseDataJSON>,
): response is CommonResponseJSON<CvsContractDataJSON> =>
  response.data?.method === 'convenience_store'

export const isCvsContract = (
  data: CvsContract | BucketRegisterResponseData | FreeBucketRegisterResponseData,
): data is CvsContract => (data as CvsContract).convenienceStoreKey !== undefined

export class CvsContractDTO implements CvsContract {
  constructor(
    public convenienceStoreKey: ConvenienceStoreKey,
    public paymentNumber: string,
    public paymentNumberLabel: string,
    public confirmationNumber: string,
    public confirmationNumberLabel: string,
    public contractDate: string,
    public contractEndDate: string,
    public paymentTerm: string,
    public paymentDetailUrl: string,
    public bucketMemberIdList: BucketMemberId[],
  ) {
  }

  static fromJSON(jsonObject: CvsContractDataJSON): CvsContractDTO {
    return new CvsContractDTO(
      jsonObject.convenienceStoreKey,
      jsonObject.paymentNumber,
      jsonObject.paymentNumberLabel,
      jsonObject.confirmationNumber,
      jsonObject.confirmationNumberLabel,
      jsonObject.contractDate,
      jsonObject.contractEndDate,
      jsonObject.paymentTerm,
      jsonObject.paymentDetailUrl,
      fromJsonBucketMemberIdList(jsonObject.bucketMemberIdList),
    )
  }
}
