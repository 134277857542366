import {
  CommonResponseDTO,
  CommonResponseStatus,
  CommonResponseStatusType,
} from '../../Network/CommonResponse'
import {MBMemberError} from '../Common/MBMemberError'
import {AsyncState} from 'react-use/lib/useAsyncFn'
import {Nullable} from '../../Common/TypeHelper'

const convertResponseStatusToMbMemberError = (
  status: CommonResponseStatusType,
  errorMessageList?: string[],
): null | MBMemberError => {
  switch (status) {
    case CommonResponseStatus.Ok:
    case CommonResponseStatus.Created:
    case CommonResponseStatus.Updated:
      return null
    case CommonResponseStatus.BadRequest:
      return new MBMemberError('BadRequest', errorMessageList)
    case CommonResponseStatus.NotFound:
      return new MBMemberError('NotFound')
    case CommonResponseStatus.Conflict:
      return new MBMemberError('Conflict')
    case CommonResponseStatus.Forbidden:
      return new MBMemberError('Forbidden')
    case CommonResponseStatus.InternalServerError:
      return new MBMemberError('ServerError')
    case CommonResponseStatus.NoAuthority:
      return new MBMemberError('SystemError')
    case CommonResponseStatus.InvalidCreditCard:
      return new MBMemberError('InvalidCreditCard')
    case CommonResponseStatus.Processing:
      return new MBMemberError('Processing')
    case CommonResponseStatus.OvertimeError:
      return new MBMemberError('OvertimeError')
    default:
      return new MBMemberError('UnknownError')
  }
}

export const memberResponseStatusCheck = (status: CommonResponseStatusType): void => {
  const error = convertResponseStatusToMbMemberError(status)
  if (error !== null) throw error
}

export const checkResponseAndGetDataOrError = <T>(
  response: CommonResponseDTO<T>,
): T | MBMemberError => {
  const error = convertResponseStatusToMbMemberError(response.status)
  if (error !== null) return error
  return response.data
}

export const checkAsyncStateAndGetDataOrError = <T>(
  result: AsyncState<CommonResponseDTO<Nullable<T>>>,
): T | MBMemberError => {
  if (result.error)
    throw new MBMemberError(result.error.message)

  if (result.value === undefined)
    throw new MBMemberError('UnknownError')

  const error = convertResponseStatusToMbMemberError(
    result.value.status,
    result.value.errorMessageList,
  )
  if (error !== null)
    return error

  if (result.value.data === undefined || result.value.data === null)
    throw new MBMemberError('UnknownError')

  return result.value.data
}

export const checkAsyncStateAndGetData = <T>(
  result: AsyncState<CommonResponseDTO<Nullable<T>>>,
): T => {
  const dataOrError = checkAsyncStateAndGetDataOrError(result)
  if (isError(dataOrError)) throw dataOrError
  return dataOrError
}

export const isError = <T>(dataOrError: T | MBMemberError)
  : dataOrError is MBMemberError => dataOrError instanceof MBMemberError
