import React, {useCallback, useLayoutEffect, useState} from 'react'
import {membersMsalInstance} from './members-msa'
import {CommonAuthProvider} from '../../Common/Components/CommonAuthProvider'
import {EventType} from '@azure/msal-browser'
import {MembersAgreementList} from '../View/Ecosystems/MembersAgreement'
import MemberAgreementRepo from '../Repo/MemberAgreementRepo'
import {CommonResponseStatus} from '../../Network/CommonResponse'
import {useAsync} from 'react-use'
import '../../i18n/config'
import {Global} from '@emotion/react'
import {MemberGlobalStyles} from '../MemberGlobalStyles'
import {MembersPageTemplate} from '../View/Molecules/BaseMembersPages/MembersPageTemplate'
import {Members500Page} from '../View/Pages/Error/Members500Page'

type MemberAuthProviderProps = {
  memberAgreementRepo: MemberAgreementRepo
  children: React.ReactNode
  isCms?: boolean
}

export const MembersAuthProvider: React.VFC<MemberAuthProviderProps> = (
  {children, memberAgreementRepo, isCms},
) => {
  const [needsAgreementCheck, setNeedsAgreementCheck] = useState(false)

  membersMsalInstance && membersMsalInstance.addEventCallback(message => {
    if (message.eventType === EventType.LOGIN_SUCCESS) {
      setNeedsAgreementCheck(true)
    }
  })

  return <CommonAuthProvider msalInstance={membersMsalInstance}>
    {needsAgreementCheck
      ? <DisagreementCheck
        memberAgreementRepo={memberAgreementRepo}
        isCms={isCms}
        offNeedsAgreementCheck={(): void => setNeedsAgreementCheck(false)}
      />
      : children}
  </CommonAuthProvider>
}

type DisagreementCheckProps = Pick<MemberAuthProviderProps,
  'memberAgreementRepo' | 'isCms'> & {
  offNeedsAgreementCheck: () => void
}

export const DisagreementCheck: React.VFC<DisagreementCheckProps> = (
  {memberAgreementRepo, isCms, offNeedsAgreementCheck},
) => {
  const [isError, setIsError] = useState(false)

  const openErrorPage = useCallback(() => setIsError(true), [])

  const getDisagreements = useAsync(() => memberAgreementRepo.getUpdated())

  const putDisagreements = useCallback(async (agreementIds: number[]) => {
    try {
      const putUpdate = await memberAgreementRepo.putUpdate(agreementIds)
      if (putUpdate.status !== CommonResponseStatus.Ok) {
        openErrorPage()
        return
      }
      offNeedsAgreementCheck()
      setIsError(false)
    } catch {
      openErrorPage()
    }
  }, [memberAgreementRepo, offNeedsAgreementCheck])

  useLayoutEffect(() => {
    if (isCms) {
      const getDocument = document
      getDocument.documentElement.className = 'members-pages'
      getDocument.head.insertAdjacentHTML('beforeend',
        '<meta name="viewport" content="width=device-width, initial-scale=1"/>' +
        '<link href="https://fonts.googleapis.com/css?family=Noto+Sans+JP" rel="stylesheet"/>')
      getDocument.body.className = 'members-pages'
    }
  }, [isCms])


  if (getDisagreements.loading) return null
  if (getDisagreements.error || !getDisagreements.value || !getDisagreements.value.data ||
    getDisagreements.value.status !== CommonResponseStatus.Ok) {
    return <MemberPageTemplateWithStyles>{<Members500Page/>}</MemberPageTemplateWithStyles>
  }

  if (getDisagreements.value.data.length == 0) {
    offNeedsAgreementCheck()
    return null
  }

  const disagreements = getDisagreements.value.data

  return <MemberPageTemplateWithStyles>{
    isError ? <Members500Page/>
      : <MembersAgreementList
        agreementList={disagreements}
        pageTitle={'利用規約再同意'}
        onAgreeClick={(agreedIdList): Promise<void> => putDisagreements(agreedIdList)}
        onDisagreeClick={offNeedsAgreementCheck}
      />
  }</MemberPageTemplateWithStyles>
}

type MemberPageTemplateWithStylesProps = {
  children: React.ReactNode
}
const MemberPageTemplateWithStyles: React.VFC<MemberPageTemplateWithStylesProps> = (
  {children},
) => {
  return <><Global styles={MemberGlobalStyles}/>
    <MembersPageTemplate>{children}</MembersPageTemplate>
  </>
}
