import styled from '@emotion/styled'
import {Box} from '@mui/material'
import {Color, Size} from '../Styles'
import React from 'react'
import {Copyright} from './Copyrights'
import {theme} from '../../AdminTheme'

export const RowFlexContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ColumnFlexContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`

type MainContainerProps = {
  children: React.ReactNode
  testId?: string
}
export const PageContentMinWidth =
  theme.membucket.size.largeTableWidth +
  (Size.MainContainerSidePadding + Size.ArticleContainerSidePadding) * 2
const StyledAdminMain = styled.main`
  width: 100%;
  min-width: ${`${PageContentMinWidth}px`};
  height: 100%;
  flex: 1 1 0;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: ${Size.MainContainerPaddingTop}px ${Size.MainContainerSidePadding}px ${Size.MainContainerPaddingBottom}px;
  background: ${Color.NewGrey100};
`
export const MainContainer: React.VFC<MainContainerProps> = (props) => {
  return (
    <StyledAdminMain
      id={'membucket-admin-main-container'}
      data-testid={props.testId}
    >
      {props.children}
    </StyledAdminMain>
  )
}

const StyledAdminMainWithCopyright = styled(StyledAdminMain)`
  padding: 0;
`
export const MainContainerWithCopyright: React.VFC<MainContainerProps> = (props) => {
  return (
    <StyledAdminMainWithCopyright data-testid={props.testId}>
      <Box
        sx={{padding: `${Size.MainContainerPaddingTop}px 24px ${Size.MainContainerPaddingBottom}px 24px`}}
      >
        {props.children}
      </Box>
      <Box mt={'auto'} mb={2} textAlign={'center'}>
        <Copyright color={Color.NewGrey900}/>
      </Box>
    </StyledAdminMainWithCopyright>
  )
}

export const ArticleContainer = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${Size.ArticleContainerMarginTop}px;
  padding: 0 ${Size.ArticleContainerSidePadding}px;
`

export const FormArea = styled(Box)`
  padding: 48px 0;
  background: ${Color.NewGrey200};
  width: ${(props): number => props.theme.membucket.size.formWidth}px;
`

export const LargeFormArea = styled(Box)`
  padding: 32px 0;
  background: ${Color.NewGrey200};
  width: ${(props): number => props.theme.membucket.size.largeFormWidth}px;
`

export const AttentionFormArea = styled(Box)`
  padding: 24px 16px;
  background: ${Color.NewGrey200};
  width: ${Size.AttentionFormWidth}px;
`

export const ButtonArea = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ErrorMessageArea = styled(Box)`
  margin-bottom: 24px;
`
