import React from 'react'
import {membersMsalInstance} from '../../../Auth/members-msa'
import {useMBMemberError} from "../../Hooks/UseMBMemberError";
import {SsoLogout} from "../../../../Common/Components/SsoLogout";

export const MembersAuthSsoLogoutPage: React.VFC = () => {
  const throwMBError = useMBMemberError()

  return <>
    <SsoLogout
      msalInstance={membersMsalInstance}
      throwMBError={throwMBError}
    />
  </>
}
