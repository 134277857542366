import React from 'react'
import {AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react'
import {MBMemberError} from '../Common/MBMemberError'
import {authRequest} from './members-msa'
import {CommonAuth} from '../../Common/CommonAuth'
import {isAuthEnabled} from '../../Common/common-msa'
import {MembersAuthContext} from './MembersAuthContext'

type MemberAuthProps = { children: React.ReactNode }
export const MembersAuth: React.VFC<MemberAuthProps> = ({children}) =>
  <MembersAuthContext.Provider value={{isMembersAuthPage: true}}>
    <CommonAuth AuthError={AuthError} authRequest={authRequest}>{children}</CommonAuth>
  </MembersAuthContext.Provider>

export const AuthError = (): JSX.Element => {
  throw new MBMemberError('AuthError')
}

type MBAuthenticatedTemplateProps = {
  children: React.ReactNode
}
export const MBAuthenticatedTemplate: React.VFC<MBAuthenticatedTemplateProps> = (props) => {
  if (!isAuthEnabled()) return <>{props.children}</>
  return <>
    <AuthenticatedTemplate>{props.children}</AuthenticatedTemplate>
  </>
}

type MBUnAuthenticatedTemplateProps = {
  children: React.ReactNode
}
export const MBUnAuthenticatedTemplate: React.VFC<MBUnAuthenticatedTemplateProps> = (props) => {
  if (!isAuthEnabled()) return null
  return <UnauthenticatedTemplate>{props.children}</UnauthenticatedTemplate>
}
