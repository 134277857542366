import {History, LocationState} from 'history'
import {NavigatorForMemberPage} from './NavigatorForMemberPage'
import {MemberInfoForCompletePage, MemberInfoForConfirmPage, MemberInfoForInputPage} from '../Props/MemberRegisterPageLocationStates'
import {MemberEditAddressInfo} from '../Props/MembersEditAddressPageLocationStates'
import {Signboard} from '../DTO/SignboardDTO'
import {MemberJoinInfo, MemberJoinInfoForInputPage} from '../Props/MembersJoinPageStates'
import {Optional} from '../../Common/TypeHelper'
import {BucketInfo} from '../DTO/BucketInfoDTO'
import {MembersLeaveBucketPageLocationState} from '../Props/MembersLeaveBucketPageLocationStates'
import {ContractInfoWithContractDate, ContractInputInfo, UsingMyCreditCardInfo} from '../Props/ContractInputInfo'
import {PaymentMethod} from '../DTO/PaymentMethodDTO'
import {ContractInfo} from '../DTO/MemberContractInfoDTO'
import {MemberVerifyIdentityInfo} from '../DTO/MemberVerifyIdentityInfoDTO'
import {MobileCarrierStartPaymentInfo} from '../DTO/MobileCarrierStartPaymentInfoDTO'
import {MembersRegisteredPageCvsLocationState, MembersRegisteredPageFreeBucketLocationState, MembersRegisteredPageLocationState} from '../View/Pages/Signup/MembersRegisteredPage'
import {CvsContract} from '../DTO/CvsContractDTO'
import {ShippingAddress, ShippingAddressInfo} from '../DTO/MemberShippingAddressDTO'
import {JoinCompletePageCvsLocationState, JoinCompletePageFreeBucketLocationState, JoinCompletePageLocationState} from '../View/Pages/Join/MembersJoinCompletePage'
import {BucketMemberId} from '../DTO/BucketMemberId'
import {EnrollmentReasonSelectedInfo} from '../View/Components/InputContentBlocks/EnrollmentReasonSelectContentBlock'
import {SignedUpSignboard} from '../DTO/SignedUpSignboardDTO'
import {ExtendMetaData, ExtendMetaDataInputInfo} from '../DTO/ExtendMetaDataDTO'

export class NavigatorForMemberPageImpl implements NavigatorForMemberPage {

  private readonly browserHistory: History
  private readonly baseUrl: string

  constructor(browserHistory: History<LocationState>) {
    this.browserHistory = browserHistory
    this.baseUrl = process.env.REACT_APP_MEMBER_CLIENT_ROOT!
  }

  goToMembersInvitationPage(): void {
    this.browserHistory.push('/invitation')
  }

  goToMembersSignupInputPage(
    memberRegisterInformationProps: MemberInfoForInputPage,
    signboard: Signboard,
    paymentMethodList: PaymentMethod[],
    extendMetaData: ExtendMetaData[],
    contractInputInfo?: ContractInputInfo,
    replaceHistory?: boolean,
  ): void {
    const params = {
      pathname: `/${signboard.signupKey}/signup/input`,
      state: {
        memberInfo: memberRegisterInformationProps,
        signboard: signboard,
        paymentMethodList: paymentMethodList,
        extendMetaData: extendMetaData,
        contractInputInfo: contractInputInfo,
      },
    }
    if (replaceHistory) {
      this.browserHistory.replace(params)
    } else {
      this.browserHistory.push(params)
    }
  }

  changeMembersSignupInputPageState(
    memberRegisterInformationProps: MemberInfoForInputPage,
    signboard: Signboard,
    paymentMethodList: PaymentMethod[],
    extendMetaData: ExtendMetaData[],
    contractInputInfo?: ContractInputInfo,
    extendMetaDataInputInfo?: ExtendMetaDataInputInfo,
  ): void {
    this.browserHistory.replace({
      state: {
        memberInfo: memberRegisterInformationProps,
        signboard: signboard,
        paymentMethodList: paymentMethodList,
        extendMetaData: extendMetaData,
        contractInputInfo: contractInputInfo,
        extendMetaDataInputInfo: extendMetaDataInputInfo,
      },
    })
  }

  backToMembersSignupInputPageCauseCreditCardError(
    memberRegisterInformationProps: MemberInfoForInputPage,
    signboard: Signboard,
    paymentMethodList: PaymentMethod[],
    contractInputInfo: ContractInputInfo,
    extendMetaData: ExtendMetaData[],
    extendMetaDataInputInfo?: ExtendMetaDataInputInfo,
  ): void {
    const params = {
      pathname: `/${signboard.signupKey}/signup/input`,
      state: {
        isInvalidCardError: true,
        memberInfo: memberRegisterInformationProps,
        signboard: signboard,
        paymentMethodList: paymentMethodList,
        contractInputInfo: contractInputInfo,
        extendMetaData,
        extendMetaDataInputInfo,
      },
    }
    this.browserHistory.push(params)
  }

  backToMembersSignupInputPageCauseMobileCarrierError(
    memberRegisterInformationProps: MemberInfoForInputPage,
    signboard: Signboard,
    paymentMethodList: PaymentMethod[],
    contractInputInfo: ContractInputInfo,
    extendMetaData: ExtendMetaData[],
    extendMetaDataInputInfo?: ExtendMetaDataInputInfo,
  ): void {
    const params = {
      pathname: `/${signboard.signupKey}/signup/input`,
      state: {
        isMobileCarrierError: true,
        memberInfo: memberRegisterInformationProps,
        signboard: signboard,
        paymentMethodList: paymentMethodList,
        contractInputInfo: contractInputInfo,
        extendMetaData,
        extendMetaDataInputInfo,
      },
    }
    this.browserHistory.push(params)
  }

  goToMembersSignupInputConfirmPage(
    memberRegisterInformationProps: MemberInfoForConfirmPage,
    signboard: Signboard,
    paymentMethodList: PaymentMethod[],
    contractInputInfo: ContractInputInfo,
    extendMetaData: ExtendMetaData[],
    extendMetaDataInputInfo?: ExtendMetaDataInputInfo,
  ): void {
    replaceAndPush(
      this.browserHistory,
      `/${signboard.signupKey}/signup/input`,
      `/${signboard.signupKey}/signup/confirm`,
      {
        memberInfo: memberRegisterInformationProps,
        signboard: signboard,
        paymentMethodList: paymentMethodList,
        contractInputInfo: contractInputInfo,
        extendMetaData: extendMetaData,
        extendMetaDataInputInfo: extendMetaDataInputInfo,
      },
    )
  }

  goToMembersRegisteredPage(
    signupKey: string,
    state: MembersRegisteredPageLocationState |
      MembersRegisteredPageCvsLocationState |
      MembersRegisteredPageFreeBucketLocationState,
  ): void {
    this.browserHistory.push({pathname: `/${signupKey}/signup/registered`, state})
  }

  goToMembersRegisteredPageForCreditCard(
    memberInfo: MemberInfoForCompletePage,
    signboard: Signboard,
    bucketMemberIdList: BucketMemberId[],
    contractInfoForCompletePage: ContractInfoWithContractDate,
  ): void {
    const state: MembersRegisteredPageLocationState = {
      contractMethod: 'credit_card',
      memberInfo, signboard, bucketMemberIdList, contractInfoForCompletePage,
    }
    this.goToMembersRegisteredPage(signboard.signupKey, state)
  }

  goToMembersRegisteredPageForMobileCarrier(
    memberInfo: MemberInfoForCompletePage,
    signboard: Signboard,
    bucketMemberIdList: BucketMemberId[],
    contractInfoForCompletePage: ContractInfoWithContractDate,
  ): void {
    const state: MembersRegisteredPageLocationState = {
      contractMethod: 'mobile_carrier',
      memberInfo, signboard, bucketMemberIdList, contractInfoForCompletePage,
    }
    this.goToMembersRegisteredPage(signboard.signupKey, state)
  }

  goToMembersRegisteredPageForConvenienceStore(
    memberInfo: MemberInfoForCompletePage,
    signboard: Signboard,
    cvsContract: CvsContract,
    contractInfoForCompletePage: ContractInfoWithContractDate,
  ): void {
    const state: MembersRegisteredPageCvsLocationState = {
      contractMethod: 'convenience_store',
      memberInfo, signboard, cvsContract, contractInfoForCompletePage,
      bucketMemberIdList: cvsContract.bucketMemberIdList,
    }
    this.goToMembersRegisteredPage(signboard.signupKey, state)
  }

  goToMembersRegisteredPageForFreeBucket(
    memberInfo: MemberInfoForCompletePage,
    signboard: Signboard,
    bucketMemberIdList: BucketMemberId[],
  ): void {
    const state: MembersRegisteredPageFreeBucketLocationState = {
      contractMethod: 'none',
      memberInfo, signboard, bucketMemberIdList,
    }
    this.goToMembersRegisteredPage(signboard.signupKey, state)
  }

  goToMembersSignupMobileCarrierTransitionPage(
    mobileCarrierStartPaymentInfo: MobileCarrierStartPaymentInfo,
  ): void {
    this.browserHistory.push({
      pathname: '/signup/payment/mobileCarrierTransition',
      state: {
        mobileCarrierStartPaymentInfo: mobileCarrierStartPaymentInfo,
      },
    })
  }

  goToMembersInvitationCompletePage(signboard: Signboard): void {
    this.browserHistory.push({
      pathname: `/${signboard.signupKey}/invitation/complete`,
      state: {
        signboard: signboard,
      },
    })
  }

  goToAccountTopPage(): void {
    this.browserHistory.push('/account')
  }

  goToAccountInformationPage(): void {
    this.browserHistory.push('/account/information')
  }

  goToEditMailPage(): void {
    this.browserHistory.push('/account/information/mail/update_input')
  }

  goToEditPasswordPage(): void {
    this.browserHistory.push('/account/information/password/update_input')
  }

  goToEditPasswordCompletePage(): void {
    this.browserHistory.push('/account/information/password/update_complete')
  }

  goToEditMailInvitationCompletePage(): void {
    this.browserHistory.push('/account/information/mail/send_complete')
  }

  goToEditMailUpdatedPage(): void {
    this.browserHistory.replace('/account/information/mail/update_complete')
  }

  goToEditAddressInputPage(): void {
    this.browserHistory.push('/account/information/address/update')
  }

  goToEditAddressConfirmPage(memberEditAddressInfo: MemberEditAddressInfo): void {
    replaceAndPush(
      this.browserHistory,
      '/account/information/address/update',
      '/account/information/address/update_confirm',
      memberEditAddressInfo,
    )
  }

  goToEditAddressCompletePage(): void {
    this.browserHistory.push('/account/information/address/update_complete')
  }

  goToEditMailMagazineInfoPage(bucketKey: string): void {
    this.browserHistory.push(`/${bucketKey}/mail_magazine`)
  }

  goToEditMailMagazineInputPage(bucketKey: string): void {
    this.browserHistory.push(`/${bucketKey}/mail_magazine/update`)
  }

  goToEditMailMagazineConfirmPage(
    bucketKey: string,
    bucketInfo: BucketInfo,
    subscribeMailMagazineIdList: number[])
    : void {
    replaceAndPush(
      this.browserHistory,
      `/${bucketKey}/mail_magazine/update`,
      `/${bucketKey}/mail_magazine/update_confirm`,
      {bucketInfo, subscribeMailMagazineIdList},
    )
  }

  goToEditMailMagazineCompletePage(bucketKey: string, bucketInfo: BucketInfo): void {
    this.browserHistory.push(
      {
        pathname: `/${bucketKey}/mail_magazine/update_complete`,
        state: bucketInfo,
      },
    )
  }

  goToEditContractInfoPage(bucketKey: string): void {
    this.browserHistory.push(`/${bucketKey}/contract`)
  }

  goToEditContractInputPage(bucketKey: string): void {
    this.browserHistory.push(`/${bucketKey}/contract/update`)
  }

  changeEditContractInputPageState(
    bucketInfo: BucketInfo,
    paymentMethodList: PaymentMethod[],
    contractInputInfo: ContractInputInfo,
    myCreditCardInfoList: UsingMyCreditCardInfo[],
    nextContractInfo: ContractInfo,
  ): void {
    this.browserHistory.replace({
      state: {
        bucketInfo,
        paymentMethodList,
        contractInputInfo,
        myCreditCardInfoList,
        nextContractInfo,
      },
    })
  }

  backToEditContractInputPageCauseCreditCardError(
    bucketInfo: BucketInfo,
    paymentMethodList: PaymentMethod[],
    myCreditCardInfoList: UsingMyCreditCardInfo[],
    contractInputInfo: ContractInputInfo,
    nextContractInfo: ContractInfo,
  ): void {
    const params = {
      pathname: `/${bucketInfo.key}/contract/update`,
      state: {
        isInvalidCardError: true,
        bucketInfo: bucketInfo,
        paymentMethodList: paymentMethodList,
        myCreditCardInfoList: myCreditCardInfoList,
        contractInputInfo: contractInputInfo,
        nextContractInfo: nextContractInfo,
      },
    }
    this.browserHistory.push(params)
  }

  backToEditContractInputPageCauseMobileCarrierError(
    bucketInfo: BucketInfo,
    contractInputInfo: ContractInputInfo,
  ): void {
    const params = {
      pathname: `/${bucketInfo.key}/contract/update`,
      state: {
        isMobileCarrierError: true,
        bucketInfo: bucketInfo,
        contractInputInfo: contractInputInfo,
      },
    }
    this.browserHistory.push(params)
  }

  goToEditContractConfirmPage(
    bucketInfo: BucketInfo,
    paymentMethodList: PaymentMethod[],
    contractInputInfo: ContractInputInfo,
    myCreditCardInfoList: UsingMyCreditCardInfo[],
    nextContractInfo: ContractInfo,
  ): void {
    replaceAndPush(
      this.browserHistory,
      `/${bucketInfo.key}/contract/update`,
      `/${bucketInfo.key}/contract/update_confirm`,
      {
        bucketInfo,
        paymentMethodList,
        contractInputInfo,
        myCreditCardInfoList,
        nextContractInfo,
      },
    )
  }

  goToEditContractMobileCarrierTransitionPage(
    mobileCarrierStartPaymentInfo: MobileCarrierStartPaymentInfo,
  ): void {
    this.browserHistory.push({
      pathname: '/contract/update/payment/mobileCarrierTransition',
      state: {
        mobileCarrierStartPaymentInfo: mobileCarrierStartPaymentInfo,
      },
    })
  }

  goToEditContractCompletePage(
    bucketInfo: BucketInfo,
    nextContractStartDate?: string,
  ): void {
    this.browserHistory.push({
      pathname: `/${bucketInfo.key}/contract/update_complete`,
      state: {bucketInfo: bucketInfo, nextContractStartDate: nextContractStartDate},
    })
  }

  goToJoinInputPage(
    memberJoinInfo: Optional<MemberJoinInfoForInputPage>,
    signboard: Optional<SignedUpSignboard>,
    paymentMethodList?: PaymentMethod[],
    myCreditCardInfoList?: UsingMyCreditCardInfo[],
    contractInputInfo?: ContractInputInfo): void {
    this.browserHistory.push({
      pathname: `/${signboard?.signupKey}/join/input`,
      state: {
        memberInfo: memberJoinInfo,
        signboard: signboard,
        paymentMethodList: paymentMethodList,
        myCreditCardInfoList: myCreditCardInfoList,
        contractInputInfo: contractInputInfo,
      },
    })
  }

  changeJoinInputPageState(
    memberJoinInfo: Optional<MemberJoinInfoForInputPage>,
    signboard: Optional<SignedUpSignboard>,
    paymentMethodList?: PaymentMethod[],
    myCreditCardInfoList?: UsingMyCreditCardInfo[],
    contractInputInfo?: ContractInputInfo,
  ): void {
    this.browserHistory.replace({
      state: {
        memberInfo: memberJoinInfo,
        signboard: signboard,
        paymentMethodList: paymentMethodList,
        myCreditCardInfoList: myCreditCardInfoList,
        contractInputInfo: contractInputInfo,
      },
    })
  }

  changeMembersJoinInputPageState(
    memberJoinInfo: Optional<MemberJoinInfoForInputPage>,
    signboard: Optional<SignedUpSignboard>,
    paymentMethodList?: PaymentMethod[],
    myCreditCardInfoList?: UsingMyCreditCardInfo[],
    contractInputInfo?: ContractInputInfo,
  ): void {
    this.browserHistory.replace({
      state: {
        memberInfo: memberJoinInfo,
        signboard: signboard,
        paymentMethodList: paymentMethodList,
        myCreditCardInfoList: myCreditCardInfoList,
        contractInputInfo: contractInputInfo,
      },
    })
  }

  backToMembersJoinInputPageCauseCreditCardError(
    memberJoinInfo: MemberJoinInfoForInputPage,
    signboard: SignedUpSignboard,
    paymentMethodList: PaymentMethod[],
    myCreditCardInfoList: UsingMyCreditCardInfo[],
    contractInputInfo: ContractInputInfo,
  ): void {
    const params = {
      pathname: `/${signboard.signupKey}/join/input`,
      state: {
        isInvalidCardError: true,
        memberInfo: memberJoinInfo,
        signboard: signboard,
        paymentMethodList: paymentMethodList,
        myCreditCardInfoList: myCreditCardInfoList,
        contractInputInfo: contractInputInfo,
      },
    }
    this.browserHistory.push(params)
  }

  backToMembersJoinInputPageCauseMobileCarrierError(
    signupKey: string,
    contractInputInfo: ContractInputInfo,
    agreedIdList: MemberJoinInfo['agreedIdList'],
    mailMagazineIdCheckedList: MemberJoinInfo['mailMagazineIdCheckedList'],
    enrollmentReason: Optional<EnrollmentReasonSelectedInfo>,
  ): void {
    const params = {
      pathname: `/${signupKey}/join/input`,
      state: {
        isMobileCarrierError: true,
        contractInputInfo: contractInputInfo,
        agreedIdList: agreedIdList,
        mailMagazineIdCheckedList: mailMagazineIdCheckedList,
        enrollmentReasonSelectedInfo: enrollmentReason,
      },
    }
    this.browserHistory.push(params)
  }

  goToJoinConfirmPage(
    memberJoinInfo: MemberJoinInfo,
    signboard: SignedUpSignboard,
    paymentMethodList: PaymentMethod[],
    myCreditCardInfoList: UsingMyCreditCardInfo[],
    contractInputInfo: ContractInputInfo,
  ): void {
    replaceAndPush(
      this.browserHistory,
      `/${signboard.signupKey}/join/input`,
      `/${signboard.signupKey}/join/confirm`,
      {
        memberInfo: memberJoinInfo,
        signboard: signboard,
        paymentMethodList: paymentMethodList,
        myCreditCardInfoList: myCreditCardInfoList,
        contractInputInfo: contractInputInfo,
      },
    )
  }

  goToJoinCompletePage(
    memberJoinInfo: MemberJoinInfo,
    signboard: Signboard,
    bucketMemberIdList: BucketMemberId[],
    contractInfoForCompletePage: ContractInfoWithContractDate,
  ): void {
    const state: JoinCompletePageLocationState = {
      contractMethod: contractInfoForCompletePage.method,
      memberInfo: memberJoinInfo,
      signboard,
      bucketMemberIdList,
      contractInfoForCompletePage: contractInfoForCompletePage,
    }
    this.browserHistory.push({pathname: `/${signboard.signupKey}/join/complete`, state})
  }

  goToJoinCompletePageForConvenienceStore(
    memberInfo: MemberJoinInfo,
    signboard: Signboard,
    contractInfoForCompletePage: ContractInfoWithContractDate,
    cvsContract: CvsContract,
  ): void {
    const state: JoinCompletePageCvsLocationState = {
      contractMethod: 'convenience_store',
      memberInfo,
      signboard,
      contractInfoForCompletePage,
      cvsContract,
      bucketMemberIdList: cvsContract.bucketMemberIdList,
    }
    this.browserHistory.push({pathname: `/${signboard.signupKey}/join/complete`, state})
  }

  goToJoinCompletePageForFreeBucket(
    memberJoinInfo: MemberJoinInfo,
    signboard: Signboard,
    bucketMemberIdList: BucketMemberId[],
  ): void {
    const state: JoinCompletePageFreeBucketLocationState = {
      contractMethod: 'none',
      memberInfo: memberJoinInfo,
      signboard,
      bucketMemberIdList,
    }
    this.browserHistory.push({pathname: `/${signboard.signupKey}/join/complete`, state})
  }

  goToMembersJoinMobileCarrierTransitionPage(
    mobileCarrierStartPaymentInfo: MobileCarrierStartPaymentInfo,
  ): void {
    this.browserHistory.push({
      pathname: '/join/payment/mobileCarrierTransition',
      state: {
        mobileCarrierStartPaymentInfo: mobileCarrierStartPaymentInfo,
      },
    })
  }

  goToMembersLeaveBucketConfirmPage(
    membersLeaveBucketPageLocationState: MembersLeaveBucketPageLocationState,
  ): void {
    replaceAndPush(
      this.browserHistory,
      `/${membersLeaveBucketPageLocationState.bucketInfo?.key}/leave`,
      `/${membersLeaveBucketPageLocationState.bucketInfo?.key}/leave_confirm`,
      membersLeaveBucketPageLocationState,
    )
  }

  goToMembersLeaveBucketCompletePage(
    membersLeaveBucketPageLocationState: MembersLeaveBucketPageLocationState,
  ): void {
    this.browserHistory.push({
      pathname: `/${membersLeaveBucketPageLocationState.bucketInfo?.key}/leave_complete`,
      state: membersLeaveBucketPageLocationState,
    })
  }

  goToMembersVerifiedIdentityPage(): void {
    this.browserHistory.push('/password/reset/verified_identity')
  }

  goToResetPasswordInputPage(
    oneTimeToken: string,
    memberInfo: MemberVerifyIdentityInfo,
  ): void {
    this.browserHistory.push({
      pathname: '/password/reset/input',
      state: {
        oneTimeToken,
        memberInfo,
      },
    })
  }

  goToResetPasswordCompletePage(): void {
    this.browserHistory.push('/password/reset/complete')
  }

  goToShippingAddressInputPage(): void {
    this.browserHistory.push('/account/information/shipping_address/input')
  }

  goToShippingAddressInputConfirmPage(shippingAddress: ShippingAddress): void {
    replaceAndPush(
      this.browserHistory,
      '/account/information/shipping_address/input',
      '/account/information/shipping_address/input_confirm',
      shippingAddress,
    )
  }

  goToShippingAddressInputCompletePage(): void {
    this.browserHistory.push('/account/information/shipping_address/input_complete')
  }

  goToEditShippingAddressInputPage(shippingAddressId: number): void {
    this.browserHistory.push(`/account/information/shipping_address/${shippingAddressId}/update`)
  }

  goToEditShippingAddressConfirmPage(
    shippingAddressInfo: ShippingAddressInfo,
  ): void {
    replaceAndPush(
      this.browserHistory,
      `/account/information/shipping_address/${shippingAddressInfo.shippingAddressId}/update`,
      `/account/information/shipping_address/${shippingAddressInfo.shippingAddressId}/update_confirm`,
      shippingAddressInfo,
    )
  }

  goToEditShippingAddressCompletePage(shippingAddressId: number): void {
    this.browserHistory.push(`/account/information/shipping_address/${shippingAddressId}/update_complete`)
  }

  goToMembersInvalidOneTimeUrlPage(): void {
    this.browserHistory.replace('/invalidOneTimeUrl')
  }

  goToMembersInvalidInvitationIdPage(): void {
    this.browserHistory.replace('/invalidInvitationId')
  }

  goToMembersInvalidUrlPage(): void {
    this.browserHistory.replace('/invalidUrl')
  }

  goToMembersServerErrorPage(): void {
    this.browserHistory.push('/serverError')
  }

  goToMembersInvalidOperationPage(): void {
    this.browserHistory.push('/invalidOperation')
  }

  goToMembersDuplicatedRegisterPage(): void {
    this.browserHistory.push('/duplicatedRegister')
  }

  goBack(): void {
    this.browserHistory.goBack()
    window.scrollTo(0, 0)
  }

  generateLogoutPageUrlWithRedirectUrl(to?: string): string {
    return new URL('logout' + (to ? `?to=${to}` : ''), this.baseUrl).href
  }
}

const replaceAndPush = (
  browserHistory: History<LocationState>,
  currentPathName: string,
  nextPathName: string,
  state: Record<string, unknown>,
): void => {
  browserHistory.replace({
    pathname: currentPathName,
    state: state,
  })
  browserHistory.push({
    pathname: nextPathName,
    state: state,
  })
}
