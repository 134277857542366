import React, {useEffect} from 'react'
import {Nullable} from "../TypeHelper";
import {PublicClientApplication} from "@azure/msal-browser";

type SsoLogoutProps = {
  msalInstance: Nullable<PublicClientApplication>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  throwMBError: (message: any) => void
}
export const SsoLogout: React.VFC<SsoLogoutProps> = (props) => {
  const {msalInstance, throwMBError} = props

  useEffect(() => {
    msalInstance && msalInstance.logoutRedirect({
      onRedirectNavigate: () => false // !BrowserUtils.isInIframe()
    }).catch(() => {
      throwMBError('SystemError')
    })
  }, [msalInstance, throwMBError])

  return null
}
