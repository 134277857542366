import React from 'react'
import {PIMStack} from '../Layouts/Bases/PIMStack'
import styled from '@emotion/styled'
import {Color} from '../../Styles'
import {MBBaseVerticalDivider} from '../Dividers'

export type PIMPCLinkListProps = {
  links: {
    id: number,
    label: string,
    href: JSX.IntrinsicElements['a']['href'],
    target?: JSX.IntrinsicElements['a']['target']
  }[]
}

const PCLink = styled.a`
  color: ${Color.Grey800};
`
const PCLinkDivider: React.VFC = () => {
  return <MBBaseVerticalDivider borderColor={Color.Grey800} width={'13px'}/>
}

export const PIMPCLinkList: React.VFC<PIMPCLinkListProps> = (props) => {
  return (
    <PIMStack
      direction={'row'}
      divider={<PCLinkDivider/>}
      spacing={'10px'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      {props.links.map((link) => (
        <PCLink
          key={`link-${link.id}`}
          href={link.href}
          target={link.target ?? '_blank'}
          rel="noopener noreferrer"
        >
          {link.label}
        </PCLink>
      ))}
    </PIMStack>
  )
}
