export type MBAdminErrorTypes =
  | 'SystemError'
  | string

export class MBAdminError extends Error {
  constructor(message?: MBAdminErrorTypes | string) {
    super(message)
    this.name = new.target.name
    Object.setPrototypeOf(this, new.target.prototype)
  }
}
