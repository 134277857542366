export type MobileCarrierStartPaymentInfoJSON = MobileCarrierStartPaymentInfo

export interface MobileCarrierStartPaymentInfo {
  startURL: string
  accessID: string
  token: string
}

export class MobileCarrierStartPaymentInfoDTO implements MobileCarrierStartPaymentInfo {
  startURL: string
  accessID: string
  token: string

  constructor(startURL: string, accessID: string, token: string) {
    this.startURL = startURL
    this.accessID = accessID
    this.token = token
  }

  static fromJSON(jsonObject: MobileCarrierStartPaymentInfoJSON): MobileCarrierStartPaymentInfoDTO {
    return new MobileCarrierStartPaymentInfoDTO(
      jsonObject.startURL,
      jsonObject.accessID,
      jsonObject.token,
    )
  }
}
