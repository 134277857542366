import {ContractMethod, ContractTerm} from '../Props/ContractInputInfo'

export interface PaymentMethodJSON {
  amount: number
  method: ContractMethod
  term: ContractTerm
}

export interface PaymentMethod {
  amount: number
  method: ContractMethod
  term: ContractTerm
}

export class PaymentMethodDTO implements PaymentMethod {
  amount: number
  method: ContractMethod
  term: ContractTerm

  constructor(amount: number, method: ContractMethod, term: ContractTerm) {
    this.amount = amount
    this.method = method
    this.term = term
  }

  static fromJSON(jsonObject: PaymentMethodJSON): PaymentMethodDTO {
    return new PaymentMethodDTO(
      jsonObject.amount,
      jsonObject.method,
      jsonObject.term,
    )
  }
}
