import {CommonResponseJSON} from '../../Network/CommonResponse'
import {CvsContract, CvsContractDataJSON} from './CvsContractDTO'
import {BucketMemberId, BucketMemberIdJSON} from './BucketMemberId'

export interface BucketRegisterResponseDataJSON {
  method: 'credit_card'
  bucketMemberIdList: BucketMemberIdJSON[]
  contractDate: string
  contractEndDate: string
}

export interface BucketRegisterResponseData {
  bucketMemberIdList: BucketMemberId[]
  contractDate: string
  contractEndDate: string
}

export class BucketRegisterResponseDataDTO implements BucketRegisterResponseData {
  constructor(
    public bucketMemberIdList: BucketMemberId[],
    public contractDate: string,
    public contractEndDate: string,
  ) {
  }

  static fromJSON(jsonObject: BucketRegisterResponseDataJSON): BucketRegisterResponseDataDTO {
    return new BucketRegisterResponseDataDTO(
      fromJsonBucketMemberIdList(jsonObject.bucketMemberIdList),
      jsonObject.contractDate,
      jsonObject.contractEndDate,
    )
  }
}

export type FreeBucketRegisterResponseDataJSON = FreeBucketRegisterResponseData

export type FreeBucketRegisterResponseData = {
  method: 'none'
  bucketMemberIdList: BucketMemberId[]
}

export const isFreeBucketResponseJSON = (
  response: CommonResponseJSON<BucketRegisterResponseDataJSON |
    CvsContractDataJSON |
    FreeBucketRegisterResponseDataJSON>,
): response is CommonResponseJSON<FreeBucketRegisterResponseDataJSON> => {
  return response.data?.method === 'none'
}

export const isFreeBucketContract = (
  data: CvsContract | BucketRegisterResponseData | FreeBucketRegisterResponseData,
): data is FreeBucketRegisterResponseData =>
  (data as FreeBucketRegisterResponseData).method === 'none'

export class FreeBucketRegisterResponseDataDTO implements FreeBucketRegisterResponseData {
  method: 'none'
  bucketMemberIdList: BucketMemberId[]

  constructor(method: 'none', bucketMemberIdList: BucketMemberId[]) {
    this.method = method
    this.bucketMemberIdList = bucketMemberIdList
  }

  static fromJSON(
    jsonObject: FreeBucketRegisterResponseDataJSON,
  ): FreeBucketRegisterResponseDataDTO {
    return new FreeBucketRegisterResponseDataDTO(
      jsonObject.method,
      fromJsonBucketMemberIdList(jsonObject.bucketMemberIdList),
    )
  }
}

export const fromJsonBucketMemberIdList = (
  jsonBucketMemberIdList: BucketMemberIdJSON[],
): BucketMemberId[] => {
  const bucketMemberIdList =
    jsonBucketMemberIdList.map(jsonBucketMemberId => BucketMemberId.fromJSON(jsonBucketMemberId))

  return bucketMemberIdList
}
