import {Optional} from '../../Common/TypeHelper'

export type EnrollmentReasonInfo = {
  id: number,
  label: string
}

export interface BucketHeaderJSON {
  id: number
  bucketKey: string
  name: string
  signedUp: boolean
  enrollmentReasonList: Optional<EnrollmentReasonInfo[]>
}

class BucketHeader {
  constructor(
    public id: number,
    public bucketKey: string,
    public name: string,
    public signedUp: boolean,
    public enrollmentReasonList: Optional<EnrollmentReasonInfo[]>,
  ) {
  }

  static fromJSON(jsonObject: BucketHeaderJSON): BucketHeader {
    return new BucketHeader(
      jsonObject.id,
      jsonObject.bucketKey,
      jsonObject.name,
      jsonObject.signedUp,
      jsonObject.enrollmentReasonList,
    )
  }
}

export default BucketHeader
