import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'

export const useScroll = (): number => {
  const scrollHeight = document.documentElement.scrollHeight
  const clientHeight = document.documentElement.clientHeight
  const [offset, setOffset] = useState(0)
  const history = useHistory()


  useEffect(() => {
    const unListen = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return (): void => {
      unListen()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('scroll', onScroll)
    return (): void => document.removeEventListener('scroll', onScroll)
  }, [scrollHeight, clientHeight])

  function onScroll(): void {
    setOffset(
      window.pageYOffset ||
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop,
    )
  }

  return offset
}
