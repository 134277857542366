import React, {useCallback} from 'react'
import styled from '@emotion/styled'
import {Color} from '../../../Styles'

export type PIMSecondaryButtonProps = {
  children: React.ReactNode
  onClick: React.MouseEventHandler<HTMLButtonElement>
  testId?: string
}

const StyledButton = styled.button`
  width: 100%;
  max-width: 305px;
  height: 47px;
  background: ${Color.White};
  border: solid 1px ${Color.Grey100};
  border-radius: 6px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .05);
  padding: 0 16px;
  font-size: 1.4rem;
  color: ${Color.Grey800};
  @media (min-width: 769px) {
    &:focus {
      opacity: 0.6;
    }
    &:hover {
      opacity: 0.6;
    }
  }
`

export const PIMSecondaryButton: React.VFC<PIMSecondaryButtonProps> = (props) => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const eventTarget = e.target as HTMLElement
      eventTarget.blur()
      props.onClick(e)
    }, [props.onClick],
  )

  return (
    <StyledButton
      data-testid={props.testId}
      onClick={handleClick}
    >
      {props.children}
    </StyledButton>
  )
}
