import {MembersHeader, MembersHeaderProps} from './MembersHeader'
import {MembersFooter} from './MembersFooter'
import React from 'react'
import {animateScroll} from 'react-scroll/modules'
import {useScroll} from '../../Hooks/UseScroll'
import styled from '@emotion/styled'
import {Color} from '../../Styles'
import {PIMFooterNavi} from '../../Ecosystems/PIMFooterNavi'

export type MembersPageTemplateProps = Pick<MembersHeaderProps, 'bucketInfo' | 'signboard'> & {
  hideFooterNavi?: boolean
  children: React.ReactNode
  dataTestId?: string
}

export const MembersPageTemplate: React.VFC<MembersPageTemplateProps> = (props) => {
  const offset = useScroll()

  const hideFooterNavi: boolean = props.hideFooterNavi || false
  const visible: string = (offset >= 100) ? 'is-show' : ''

  return (
    <ContainerArea data-testid={props.dataTestId ?? 'members-page-template'}>
      <MembersHeader bucketInfo={props.bucketInfo} signboard={props.signboard}/>

      <ContentArea id="content">{props.children}</ContentArea>

      <FooterArea>
        {(!hideFooterNavi) && <PIMFooterNavi/>}
        <MembersFooter/>
      </FooterArea>
      {
        <div className={`link-top ${visible}`}>
          <p onClick={(): void => animateScroll.scrollToTop()}>
            <span>PAGETOP</span>
          </p>
        </div>
      }
    </ContainerArea>
  )
}

const ContainerArea = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${Color.Grey50};
  overflow: hidden;
`

const ContentArea = styled.div`
  flex: 1;
  padding: 0 15px 88px;
`

const FooterArea = styled.div`
  width: 100%;
`
