import FetchWrapper from '../../Network/FetchWrapper'
import MemberAgreementRepo from './MemberAgreementRepo'
import AgreementDTO, {AgreementJSON} from '../DTO/AgreementDTO'
import {CommonResponseDTO, CommonResponseJSON} from '../../Network/CommonResponse'
import {withMembersAuth} from '../../Network/BrowserFetchWrapper'

class NetworkMemberAgreementRepo implements MemberAgreementRepo {
  private fetchWrapper: FetchWrapper
  private serverUrl = process.env.REACT_APP_MEMBUCKET_ADMIN_APP_SERVER_URL

  constructor(fetchWrapper: FetchWrapper) {
    this.fetchWrapper = fetchWrapper
  }

  get(signupKey: string): Promise<CommonResponseDTO<AgreementDTO[]>> {
    const url = `${this.serverUrl}/v1/m/p/agreements?signup_key=${signupKey}`
    return this.fetchWrapper.getByJson(url)
      .then((response: CommonResponseJSON<AgreementJSON[]>) => {
        return CommonResponseDTO.fromArray(
          response,
          AgreementDTO.fromJSON,
        )
      })
  }

  getUpdated(): Promise<CommonResponseDTO<AgreementDTO[]>> {
    return this.fetchWrapper.getByJson(`${this.serverUrl}/v1/m/r/members/self/disagreements`, withMembersAuth())
      .then((response: CommonResponseJSON<AgreementJSON[]>) => CommonResponseDTO.fromArray(
        response, AgreementDTO.fromJSON,
      ))
  }

  putUpdate(agreementIdCheckedList: number[]): Promise<CommonResponseDTO<null>> {
    return this.fetchWrapper.putJson(
      `${this.serverUrl}/v1/m/r/members/self/disagreements`,
      {agreementIdCheckedList: agreementIdCheckedList},
      withMembersAuth(),
    ).then((response: CommonResponseJSON<null>) => CommonResponseDTO.ofNullData(response))
  }
}

export default NetworkMemberAgreementRepo
