export type MBMemberErrorTypes =
  | 'BadRequest'
  | 'InvalidOperation'
  | 'Conflict'
  | 'NotFound'
  | 'ServerError'
  | 'InvalidOneTimeUrl'
  | 'UnknownError'
  | 'SystemError'
  | 'AuthError'
  | 'OvertimeError'
  | 'Processing'
  | 'DisagreementError'
  | string

export class MBMemberError extends Error {
  public errorMessageList?: string[]

  constructor(
    message?: MBMemberErrorTypes | string,
    errorMessageList?: string[],
  ) {
    super(message)
    this.name = new.target.name
    this.errorMessageList = errorMessageList
    Object.setPrototypeOf(this, new.target.prototype)
  }
}
