export type SingleSelectItem = {
  id: string
  type: 'singleSelect'
  label: string
  required: boolean
  requiredMessage?: string
  selected?: number
  options: { id: number, label: string }[]
}

export type SingleTextItem = {
  id: string
  type: 'singleText'
  label: string
  required: boolean
  requiredMessage?: string
  value?: string
  size?: { min?: number, max?: number, message?: string }
  pattern?: { regexp?: string, message?: string }
}

export type ExtendMetaDataItem = SingleSelectItem | SingleTextItem

export type ExtendMetaData = {
  header: string
  items: ExtendMetaDataItem[]
  description?: string
}

export class ExtendMetaDataDTO implements ExtendMetaData {
  header: string
  description?: string
  items: ExtendMetaDataItem[]

  constructor(header: string, items: ExtendMetaDataItem[], description?: string) {
    this.header = header
    this.description = description
    this.items = items
  }

  static fromJSON(jsonObject: ExtendMetaData): ExtendMetaDataDTO {
    return new ExtendMetaDataDTO(
      jsonObject.header,
      jsonObject.items,
      jsonObject.description,
    )
  }
}

export type ExtendMetaDataInputInfo = { [key: string]: number | string | undefined }
