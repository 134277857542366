import React from 'react'
import {PIMPCLinkListProps} from './PIMPCLinkList'
import {PIMStack} from '../Layouts/Bases/PIMStack'
import styled from '@emotion/styled'
import {Color} from '../../Styles'
import {MBBaseDivider} from '../Dividers'
import {MemberCircleChevronRightIcon} from '../Icons/MemberCircleChevronRightIcon'

export type PIMSPLinkListProps = PIMPCLinkListProps

const SPLink = styled.a`
  width: 100%;
  background-color: ${Color.PrimarySubColor};
  color: ${Color.White};
  text-decoration: none;
  padding: 10px 7% 10px 30px;
`
const SPLinkDivider: React.VFC = () => {
  return <MBBaseDivider borderColor={Color.Grey200} width={'100%'}/>
}

export const PIMSPLinkList: React.VFC<PIMSPLinkListProps> = (props) => {
  return (
    <PIMStack
      divider={<SPLinkDivider/>}
      alignItems={'center'}
      justifyContent={'center'}
      showEndDivider
    >
      {props.links.map((link) => (
        <SPLink
          key={`link-${link.id}`}
          href={link.href}
          target={link.target ?? '_blank'}
          rel="noopener noreferrer"
        >
          <PIMStack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            {link.label}
            <MemberCircleChevronRightIcon/>
          </PIMStack>
        </SPLink>
      ))}
    </PIMStack>
  )
}
