import {Member} from './MemberSelfDTO'
import {Agreement, AgreementJSON} from './AgreementDTO'

export type MemberVerifyIdentityInfoJSON = Pick<Member, 'mail' | 'familyName' | 'firstName'>
  & { agreementList: AgreementJSON[] }

export interface MemberVerifyIdentityInfo {
  mail: string
  name: string
  agreementList: Agreement[]
}

export class MemberVerifyIdentityInfoDTO implements MemberVerifyIdentityInfo {
  constructor(
    public mail: string,
    public name: string,
    public agreementList: Agreement[],
  ) {
  }

  static fromJSON(jsonObject: MemberVerifyIdentityInfoJSON)
    : MemberVerifyIdentityInfoDTO {
    return new MemberVerifyIdentityInfoDTO(
      jsonObject.mail,
      `${jsonObject.familyName} ${jsonObject.firstName}`,
      jsonObject.agreementList,
    )
  }
}
