import styled from '@emotion/styled'
import React from 'react'
import {DEFAULT_SPACING} from '../../../Styles'

export type PIMStackProps = {
  children: React.ReactNode
  direction?: 'column' | 'row'
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'baseline'
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between'
  divider?: React.ReactNode
  showFirstDivider?: boolean
  showEndDivider?: boolean
  spacing?: string | number
  testId?: string
}

const getGapStyle = ({spacing}: PIMStackProps): string => {
  if (!spacing) {
    return 'normal'
  }
  if (typeof spacing === 'string') {
    return spacing
  }
  return `${DEFAULT_SPACING * spacing}px`
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: ${({direction}: PIMStackProps): string => direction ?? 'column'};
  align-items: ${({alignItems}: PIMStackProps): string => alignItems ?? 'normal'};
  justify-content: ${({justifyContent}: PIMStackProps): string => justifyContent ?? 'normal'};
  gap: ${getGapStyle};
`

export const PIMStack: React.VFC<PIMStackProps> = (props) => {
  const {children, divider, showFirstDivider, showEndDivider, testId, ...rest} = props
  return (
    <StyledDiv {...rest} data-testid={testId}>
      {showFirstDivider && <>{divider}</>}
      {divider ? joinChildren(children, divider) : children}
      {showEndDivider && <>{divider}</>}
    </StyledDiv>
  )
}

const joinChildren = (
  children: React.ReactNode,
  divider: React.ReactNode,
): React.ReactNode => {
  const childrenArray = React.Children.toArray(children).filter(Boolean)

  return childrenArray.map((child, idx) => {
    if (idx > 0) {
      return <React.Fragment key={`join-children-${idx}`}>{divider}{child}</React.Fragment>
    }
    return <React.Fragment key={`join-children-${idx}`}>{child}</React.Fragment>
  })
}
