import dayjs from 'dayjs'
import {formatDate} from '../../../Common/Formatter'
import {Optional} from '../../../Common/TypeHelper'
import {EnrollmentReasonSelectedInfo} from '../../View/Components/InputContentBlocks/EnrollmentReasonSelectContentBlock'
import {EnrollmentReasonInfo} from '../../DTO/BucketHeader'
import i18next from '../../../i18n/config'
import {ExtendMetaData, ExtendMetaDataInputInfo, SingleSelectItem, SingleTextItem} from '../../DTO/ExtendMetaDataDTO'
import {ExtendMetaDataErrors} from './ValidateMembersSignupInput'

export const uniq = (array: string[]): string[] => Array.from(new Set(array))

export const validateAgreeCheck = (agreedIdList: number[]): string[] => {
  if (agreedIdList.length > 0) {
    return []
  }

  return [
    '利用規約への同意が必要です。',
    '上記｢利用規約｣のリンク先より最後まで内容をご確認の上、｢同意する｣ボタンを押していただくとチェックボックスにチェックされます。',
  ]
}

export const validateMail = (value: string, isLoginId?: boolean): string[] => {
  const fieldMessage = isLoginId
    ? i18next.t('パスワード再設定用メールアドレス入力.本人確認メールアドレス入力.ラベル メールアドレス')
    : 'メールアドレス'

  return uniq([
    ...validateRequired(value, fieldMessage),
    ...validateMaxLength(value, 100),
    ...validateHalfWidth(value),
    ...validateMailAddressFormat(value, fieldMessage),
  ])
}

export const validateFamilyName = (value: string): string[] => {
  return uniq([
    ...validateRequired(value, 'お名前（姓）'),
    ...validateMaxLength(value, 20),
  ])
}

export const validateFirstName = (value: string): string[] => {
  return uniq([
    ...validateRequired(value, 'お名前（名）'),
    ...validateMaxLength(value, 20),
  ])
}

export const validateFamilyNameKana = (value: string): string[] => {
  return uniq([
    ...validateRequired(value, 'フリガナ（セイ）'),
    ...validateMaxLength(value, 20),
    ...validateKana(value),
  ])
}

export const validateFirstNameKana = (value: string): string[] => {
  return uniq([
    ...validateRequired(value, 'フリガナ（メイ）'),
    ...validateMaxLength(value, 20),
    ...validateKana(value),
  ])
}

export const validateGender = (value: string): string[] => {
  return uniq([
    ...validateSelectRequired(value, '性別'),
  ])
}

export const validateBirthdayYear = (value: string): string[] => {
  return uniq([
    ...validateSelectRequired(value, '年'),
  ])
}

export const validateBirthdayMonth = (value: string): string[] => {
  return uniq([
    ...validateSelectRequired(value, '月'),
  ])
}

export const validateBirthdayDay = (value: string): string[] => {
  return uniq([
    ...validateSelectRequired(value, '日'),
  ])
}

export const validateBirthdayCombination = (
  birthdayYear: string,
  birthdayMonth: string,
  birthdayDay: string,
): string[] => {
  if (birthdayYear === '' || birthdayMonth === '' || birthdayDay === '') {
    return []
  }
  return validateBirthday(`${birthdayYear}/${birthdayMonth}/${birthdayDay}`)
}

export const validateBirthday = (value: string): string[] => {
  return uniq([
    ...validateDateFormat(value),
  ])
}

export const validatePostCode = (value: string): string[] => {
  return uniq([
    ...validateRequired(value, '郵便番号'),
    ...validatePostCodeFormat(value),
  ])
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const validateAddressPrefectureCode = (value: string): string[] => {
  return uniq([
  ])
}

export const validateAddressCity = (value: string): string[] => {
  return uniq([
    ...validateMaxLength(value, 25),
  ])
}

export const validateAddressStreet = (value: string): string[] => {
  return uniq([
    ...validateMaxLength(value, 30),
  ])
}

export const validateAddressBuilding = (value: string): string[] => {
  return uniq([
    ...validateMaxLength(value, 50),
  ])
}

export const validatePhoneMobile = (value: string): string[] => {
  return uniq([
    ...validateMobilePhonePattern(value),
  ])
}

export const validatePhoneTel = (value: string): string[] => {
  return uniq([
    ...validatePhoneTelPattern(value),
  ])
}

export const validatePhone = (value: string): string[] => {
  return uniq([
    ...validateRequired(value, '電話番号'),
    ...validatePhonePattern(value),
  ])
}

// eslint-disable-next-line max-len
export const validatePassword = (password: string): string[] => {
  const patternCheckResult = validatePasswordPattern(password, 'パスワード', '正しい条件で')
  return uniq([
    ...validateRequired(password, 'パスワード'),
    ...patternCheckResult,
  ])
}

export const validateBucketLeaveReason = (value: string): string[] => {
  return uniq([
    ...validateMaxLength(value, 4000),
  ])
}

export const validateShippingAddressPrefectureCode = (value: string): string[] => {
  return uniq([
    ...validateSelectRequired(value, '都道府県'),
  ])
}

export const validateShippingAddressCity = (value: string): string[] => {
  return uniq([
    ...validateRequired(value, '市区町村'),
    ...validateMaxLength(value, 25),
  ])
}

export const validateShippingAddressStreet = (value: string): string[] => {
  return uniq([
    ...validateRequired(value, '以降の住所', '以降の住所がない方は｢なし｣と入力してください。'),
    ...validateMaxLength(value, 30),
  ])
}

export type EnrollmentReasonValidateParam = {
  candidates: EnrollmentReasonInfo[]
  selected: Optional<EnrollmentReasonSelectedInfo>
}
export const validateEnrollmentReason = (
  value: EnrollmentReasonValidateParam,
): string[] => {
  const {candidates, selected} = value
  const field = i18next.t('新規登録情報入力.入会動機選択.見出し')
  const errorMessageList = (): string[] => uniq([validateSelectErrorMessage(field)])

  if (candidates.length === 0) return []
  if (!selected || !selected.enrollmentReasonId) return errorMessageList()
  if (candidates.map(e => e.id).includes(selected.enrollmentReasonId)) return []

  return errorMessageList()
}

export type ValidateExtendMetaDataParam = {
  extendMetaData: Optional<ExtendMetaData[]>
  inputState: Optional<ExtendMetaDataInputInfo>
}
export const validateExtendMetaData = (
  value: ValidateExtendMetaDataParam,
): Optional<ExtendMetaDataErrors> => {
  const {extendMetaData, inputState} = value
  if (
    extendMetaData === undefined
    || inputState === undefined
    || extendMetaData.length === 0
  ) {
    return undefined
  }

  const errors: ExtendMetaDataErrors = {}
  extendMetaData.map(block => {
    block.items.map(item => {
      if (inputState[item.id] === undefined) {
        if (item.required) {
          errors[item.id] = [
            item.requiredMessage ?? (
              item.type === 'singleSelect' ?
                validateSelectErrorMessage(item.label) :
                validateInputErrorMessage(item.label)
            )
          ]
        }
        return
      }
      switch (item.type) {
        case 'singleSelect':
          validateExtendMetaDataItemSingleSelect(inputState[item.id] as number, item, errors)
          break
        case 'singleText':
          validateExtendMetaDataItemSingleText(inputState[item.id] as string, item, errors)
          break
        default:
          break
      }
    })
  })

  return errors
}

const validateExtendMetaDataItemSingleSelect = (
  itemValue: number,
  item: SingleSelectItem,
  errors: ExtendMetaDataErrors,
): void => {
  if (!item.options?.find(opt => opt.id === itemValue)) {
    errors[item.id] = [
      item.requiredMessage ?? validateSelectErrorMessage(item.label)
    ]
  }
}

const validateExtendMetaDataItemSingleText = (
  itemValue: string,
  item: SingleTextItem,
  errors: ExtendMetaDataErrors,
): void => {
  if (itemValue.length <= 0) {
    if (item.required) {
      errors[item.id] = [
        item.requiredMessage ?? validateInputErrorMessage(item.label)
      ]
    }
  } else if (
    itemValue.length < (item.size?.min ?? 0) ||
    itemValue.length > (item.size?.max ?? Number.MAX_VALUE)
  ) {
    errors[item.id] = [
      item.size?.message ?? `${item.label}は${item.size?.min ?? 0}文字から${item.size?.max ?? Number.MAX_VALUE}文字で入力してください。`
    ]
  }
}

export const isBlankString = (value: string): boolean => {
  return (value.length === 0)
}

export const isValidRegExpPattern = (value: string, pattern: RegExp): boolean => {
  return !!value.match(pattern)
}

const validateInputErrorMessage = (field: string): string => {
  return `${field}を入力してください。`
}

export const validateRequired
  = (value: string, field: string, description: string = ''): string[] => {
  if (!isBlankString(value)) {
    return []
  }
  return [`${validateInputErrorMessage(field)}${description}`]
}

const validateSelectErrorMessage = (field: string): string => {
  return `${field}を選択してください。`
}

export const validateSelectRequired = (value: string, field: string): string[] => {
  if (!isBlankString(value)) {
    return []
  }
  return [validateSelectErrorMessage(field)]
}

export const validateMaxLength = (value: string, maxLength: number): string[] => {
  if (value.length <= maxLength) {
    return []
  }
  return [`${maxLength}文字以内で入力してください。`]
}

export const validateMailAddressFormat = (value: string, field: string): string[] => {
  if (isBlankString(value)) {
    return []
  }
  if (isValidRegExpPattern(
    value,
    /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/g)
  ) {
    return []
  }
  return [`正しい${validateInputErrorMessage(field)}`]
}

export const validateHalfWidth = (value: string): string[] => {
  if (isBlankString(value)) {
    return []
  }
  if (isValidRegExpPattern(value, /^[ -~]*$/g)) {
    return []
  }
  return ['半角で入力してください。']
}

export const validateKana = (value: string): string[] => {
  if (isBlankString(value)) {
    return []
  }
  if (isValidRegExpPattern(value, /^([\u30A0-\u30FF])+$/g)) {
    return []
  }
  return ['全角カタカナで入力してください。']
}

export const validateSamePassword = (password: string, passwordConfirm: string): string[] => {
  if (password === passwordConfirm) {
    return []
  }
  return [validateInputErrorMessage('パスワード(確認)にはパスワードと同じもの')]
}

export const validatePostCodeFormat = (value: string): string[] => {
  if (isBlankString(value)) {
    return []
  }
  if (isValidRegExpPattern(value, /^\d{7}$/)) {
    return []
  }
  return ['半角数字7桁(ハイフンなし)で入力してください。']
}

export const validateDateFormat = (value: string): string[] => {
  const parsedDay = formatDate(dayjs(value))
  if (value === parsedDay) {
    return []
  }
  return [validateInputErrorMessage(`正しい生年月日`)]
}

export const validateAnyPhoneRequired = (mobile: string, tel: string): string[] => {
  if (!isBlankString(mobile) || !isBlankString(tel)) {
    return []
  }
  return [validateInputErrorMessage('電話番号は携帯または固定いずれか')]
}

export const validateMobilePhonePattern = (value: string): string[] => {
  if (isBlankString(value)) {
    return []
  }
  if (isValidRegExpPattern(value, /^\d{11}$/)) {
    return []
  }
  return ['半角数字11桁(ハイフン・カッコなし)で入力してください。']
}

export const validatePhoneTelPattern = (value: string): string[] => {
  if (isBlankString(value)) {
    return []
  }
  if (isValidRegExpPattern(value, /^\d{10}$/)) {
    return []
  }
  return ['半角数字10桁(ハイフン・カッコなし)で入力してください。']
}

export const validatePhonePattern = (value: string): string[] => {
  if (isBlankString(value)) {
    return []
  }
  if (isValidRegExpPattern(value, /^\d{10,11}$/)) {
    return []
  }
  return ['半角数字10〜11桁(ハイフン・カッコなし)で入力してください。']
}

export const validatePasswordPattern =
  (value: string, field: string, condition: string = ''): string[] => {
    if (isBlankString(value)) {
      return []
    }
    if (isValidRegExpPattern(
      value,
      /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[ -/:-@[-`{-~])[ -~]{10,64}$/g)) {
      return []
    }
    return [`${field}を${condition}入力してください。`]
  }
